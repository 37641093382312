import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'
import { Scripts } from './Scripts'
import { registerLicense } from '@syncfusion/ej2-base'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
registerLicense(process.env.REACT_APP_SYNCFUSION_KEY)

window.React = React

root.render(
  <StrictMode>
    <Scripts />
    <App />
  </StrictMode>,
)
