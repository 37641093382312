import { type FC, memo, useCallback, useEffect, useState } from 'react'
import { useToggle } from 'react-use'

import dayjs from 'dayjs'

import { useAuth } from 'app/providers'

import { ModalAction } from 'common/components/Modal'
import { RoomThumbnails } from 'common/components/Thumbnail/RoomThumbnails/RoomThumbnails'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import { MODAL_LABELS } from 'common/constants/modalConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import {
  BOOKING_CONSTANTS,
  RESET_NEW_LEASE_ROOM_INFO,
  SET_NEW_LEASE_ROOMS,
  TOGGLE_IS_CREATE_NEW_LEASE,
  UPDATE_NEW_LEASE_PRICE,
  UPDATE_NEW_LEASE_ROOM_INFO,
  useLazyFetchRoomDaysQuery,
} from 'features/Booking'
import Lease from 'features/Booking/pages/Lease'
import { useLazyFetchRoomsQuery } from 'features/Booking/state/api/bookingApi'
import { CART_CONSTANTS, UPDATE_CART_CONTENT, useCartManager } from 'features/Cart'
import { HOME_CONSTANTS } from 'features/Home/constants/homeConstants'
import { OptionOverlay, SET_OVERLAY_OPTION, TOGGLE_IS_OPEN_OPTION_OVERLAY } from 'features/Option'

import styles from './chooseRoom.module.scss'
import { useParams } from 'react-router-dom'

export const ChooseRoom: FC = memo(() => {
  const [loadingRooms, setLoadingRooms] = useState({})
  const [isOpenClearCartModal, toggleIsOpenClearCartModal] = useToggle(false)

  const dispatch = useAppDispatch()

  const { user } = useAuth()
  const { siteId } = useParams()
  const { deleteCart } = useCartManager()

  const [fetchRoomDays, { isFetching: isFetchingCalendar }] = useLazyFetchRoomDaysQuery()
  const [fetchRoomsAndSites, { data: leaseData, isFetching }] = useLazyFetchRoomsQuery()
  const { isCreateNewLease, newLeaseRooms, newLeaseInfo } = useAppSelector(
    (state) => state.leaseReducer,
  )

  const { room, selectedDays } = newLeaseInfo

  useEffect(() => {
    if (!siteId) {
      return
    }

    fetchRoomsAndSites(siteId)
  }, [siteId])

  const fetchRoomCalendar = async (id?: number | null, date?: string) => {
    dispatch(UPDATE_NEW_LEASE_ROOM_INFO({ isLoading: true }))
    const response = await fetchRoomDays({
      id: id || room?.id,
      date: date,
      siteId,
    })
    if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS))
      dispatch(UPDATE_NEW_LEASE_ROOM_INFO({ days: response.data?.days, isLoading: false }))
    return response
  }

  const handleSelectRoom = useCallback(
    async (selectedRoom: ISiteRoom): Promise<void> => {
      setLoadingRooms((prevState) => ({ ...prevState, [selectedRoom.id]: true }))

      dispatch(RESET_NEW_LEASE_ROOM_INFO())
      const response = await fetchRoomCalendar(selectedRoom.id, dayjs().format('YYYY-MM'))
      if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
        dispatch(
          UPDATE_NEW_LEASE_ROOM_INFO({
            room: { ...selectedRoom, isNewRoom: true },
            selectedDay: null,
          }),
        )
      }
      dispatch(TOGGLE_IS_CREATE_NEW_LEASE())
      setLoadingRooms((prevState) => ({ ...prevState, [selectedRoom.id]: false }))
    },
    [newLeaseRooms],
  )

  useEffect(() => {
    dispatch(UPDATE_NEW_LEASE_PRICE())
  }, [room, selectedDays])

  const handleClearCartLocation = async (): Promise<void> => {
    dispatch(UPDATE_CART_CONTENT(null))
    dispatch(SET_NEW_LEASE_ROOMS([]))
    toggleIsOpenClearCartModal()

    await deleteCart()
  }

  const handleSelectOption = useCallback((option: ISiteRoom): void => {
    dispatch(SET_OVERLAY_OPTION(option))
    dispatch(TOGGLE_IS_OPEN_OPTION_OVERLAY())
  }, [])

  return (
    <div className={styles.parent}>
      <ModalAction
        isOpen={isOpenClearCartModal}
        cancelText={BUTTON_CONSTANTS.CANCEL}
        onClose={toggleIsOpenClearCartModal}
        onConfirm={handleClearCartLocation}
        title={MODAL_LABELS.CONFIRM_CLEAR_CART}
        confirmText={BUTTON_CONSTANTS.CONTINUE}
        description={CART_CONSTANTS.CONFIRM_CLEAR_MESSAGE}
      />

      <div className={styles.parentHeader}>
        <h1>{HOME_CONSTANTS.SELECT_ROOM}</h1>
      </div>

      <section className={styles.parentRooms}>
        <RoomThumbnails
          skeletonItems={3}
          isLoadingRooms={loadingRooms}
          isFetching={isFetching}
          rooms={leaseData?.rooms}
          handleClick={handleSelectRoom}
          isLoading={isFetchingCalendar}
        />
      </section>
      {user && (
        <>
          <section className={styles.parentRooms}>
            {!isFetching && (
              <h2 className={styles.parentRoomsTitle}>
                <span>{HOME_CONSTANTS.SELECT_EXTRAS}</span>
              </h2>
            )}

            <RoomThumbnails
              skeletonItems={5}
              isFetching={isFetching}
              disabled={!newLeaseRooms?.length}
              rooms={leaseData?.site_options}
              handleClick={handleSelectOption}
            />
          </section>
        </>
      )}

      <Lease
        title={BOOKING_CONSTANTS.CHOOSE_DATE_AND_TIME}
        isCreateLease={isCreateNewLease}
        fetchRoomCalendar={fetchRoomCalendar}
      />

      <OptionOverlay />
    </div>
  )
})
