import { Col, Row, Skeleton } from 'antd'
import { UtilService } from 'common/services/utilService'
import { WidgetPhoto } from 'features/Widget/components/WidgetPhoto/WidgetPhoto'
import styles from './userWidget.module.scss'
import { Icon } from '@iconify/react'

import { useFetchProfileQuery } from 'features/SettingsOld/state/api/settingsApi'

export const UserWidget = () => {
  const { data, isLoading } = useFetchProfileQuery()

  const { profile } = data || {}
  const initials = UtilService.getInitials(profile?.first_name, profile?.last_name)
  // const formatPoints = new Intl.NumberFormat('en-US', {
  //   notation: 'compact',
  // }).format(points)

  //   const activeLevel = useMemo(() => {
  //     return items.findLast((item) => points >= item.points)
  //   }, [points, items])

  if (isLoading) return <Skeleton.Input className={styles.skeleton} active size='large' />

  return (
    <div>
      <Row gutter={12}>
        <Col>
          <WidgetPhoto initials={initials} hasShadow={false} src={profile?.image?.url} />
        </Col>
        <Col>
          <h5 className={styles.name}>
            {profile?.salutation} {profile?.first_name} {profile?.last_name}
          </h5>
          {/* {isDoctor && (
            <div className={styles.levelContainer}>
              <StarIcon />
              <p className={styles.level}>{activeLevel?.name}</p>
              <span className={styles.levelValue}>({formatPoints} points)</span>
            </div>
          )} */}
          <div className={styles.masterAccessContainer}>
            <Icon
              icon='uim:lock-access'
              className={styles.arrowIcon}
              style={{ color: 'var(--tertiary-100)' }}
              width={16}
              height={16}
            />
            <div className={styles.masterAccessText}>
              Master Access Code{' '}
              <span className={styles.masterAccessTextValue}>{`(${profile?.access_code})`}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
