import { BOOKING_STATUS_CODE } from 'common/constants/bookingConstants'
import { BOOKING_TYPE } from 'features/Booking/constants/booking.constants'
import { PATH_SEGMENT } from 'routes/pathSegments'

const config = {
  getTabs: (
    bookingType: BOOKING_TYPE,
    statusCode: BOOKING_STATUS_CODE,
    isStaff: boolean,
    isSuperStaff: boolean,
  ) => {
    const showLimitedTabs = !isStaff || isSuperStaff
    switch (bookingType) {
      case BOOKING_TYPE.MAILBOX:
        return [
          {
            path: PATH_SEGMENT.DETAILS,
            title: 'Booking Details',
          },
          showLimitedTabs && {
            path: PATH_SEGMENT.STAFF,
            title: 'Staff',
          },
          showLimitedTabs && {
            path: PATH_SEGMENT.DOCUMENTS,
            title: 'Documents',
          },
        ].filter(Boolean)

      case BOOKING_TYPE.BOOKING:
        return [
          {
            path: PATH_SEGMENT.SCHEDULE,
            title: 'Schedule',
          },
          {
            path: PATH_SEGMENT.DETAILS,
            title: 'Booking Details',
          },
          showLimitedTabs && {
            path: PATH_SEGMENT.STAFF,
            title: 'Staff',
          },
          showLimitedTabs && {
            path: PATH_SEGMENT.DOCUMENTS,
            title: 'Documents',
          },
          {
            path: PATH_SEGMENT.SHOP,
            title: 'Shop',
            disabled: statusCode === BOOKING_STATUS_CODE.FINISHED,
          },
        ].filter(Boolean)

      default:
        return [
          {
            path: PATH_SEGMENT.DETAILS,
            title: 'Booking Details',
          },
          showLimitedTabs && {
            path: PATH_SEGMENT.DOCUMENTS,
            title: 'Documents',
          },
        ].filter(Boolean)
    }
  },
}

export type BookingConfigType = typeof config

export const bookingConfig: BookingConfigType = Object.freeze(config)
