import { TABLE_ACTIONS } from 'common/constants/tableConstants'

export enum PATIENTS_LABELS {
  MY_PATIENTS = 'My Patients',
  CONFIRM_DELETE_PATIENT = 'Are you sure you want to delete ',
  CONFIRM_NOTIFY = 'Are you sure you want to notify your patients via text message?',
  CONFIRM_MANUAL_CHECK_IN = 'Are you sure you want to check-in this patient?',
}

export const PATIENTS_TABLE_ACTIONS = [
  // TABLE_ACTIONS.SEND_CODE,
  TABLE_ACTIONS.DELETE,
  TABLE_ACTIONS.EDIT,
]
