import { Col, Row, Skeleton } from 'antd'

import { TagStatus } from 'common/components/TagStatus/TagStatus'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { UtilService } from 'common/services/utilService'
import type { TAG_STATUS } from 'common/types/tagStatusType'

import styles from './promotionHeader.module.scss'
import type { FC } from 'react'
import { memo } from 'react'
import type { PromotionHeaderProps } from './promotionHeader.types'

export const PromotionHeader: FC<PromotionHeaderProps> = memo(({ isLoading, promotion }) => {
  if (isLoading) return <Skeleton.Image active className={styles.imageSkeleton} />

  return (
    <div className={styles.header}>
      <img
        src={promotion?.image}
        className={styles.headerImage}
        alt={ALT_CONSTANTS.ACTIVE_BOOKING_IMAGE}
      />
      <Row className={styles.headerContent} justify='space-between'>
        <Col xs={24} sm={12} className={styles.headerLeftSide}>
          <p className={styles.headerLeftSideTitle}>Package</p>
          <p className={styles.headerLeftSideSubTitle}>{promotion?.code}</p>
        </Col>
        <Col xs={24} sm={12} className={styles.headerRightSide}>
          <TagStatus
            status={UtilService.capitalizeFirstLetter(promotion?.status) as TAG_STATUS}
            isLarge
            isDark
          />

          <p className={styles.headerRightSideTitle}>{promotion?.site_name}</p>
        </Col>
      </Row>
    </div>
  )
})
