import { Button as AntButton } from 'antd'
import { Button } from 'common/components/Button/Button'
import { ReactComponent as AnnounceIcon } from 'assets/icons/announce.svg'
import styles from './announce.module.scss'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import type { ButtonProps } from 'antd'
import { BUTTON_SEVERITY } from 'common/constants/buttonConstants'

dayjs.extend(utc)
dayjs.extend(timezone)

export const AnnounceButton = ({
  onClick,
  buttonLabel,
  ...rest
}: ButtonProps & {
  buttonLabel: string
}) => {
  return (
    <div className={styles.announceButtonContainer}>
      <Button
        onClick={onClick}
        icon={<AnnounceIcon />}
        severity={BUTTON_SEVERITY.SUCCESS_FILLED}
        htmlType='button'
        {...rest}>
        {buttonLabel}
      </Button>
    </div>
  )
}

export const AnnounceIconButton = ({ onClick, ...rest }: ButtonProps) => {
  return (
    <AntButton
      className={styles.announceIconButton}
      onClick={onClick}
      icon={<AnnounceIcon width={16} height={16} />}
      htmlType='button'
      {...rest}
    />
  )
}
