import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import type { IOption, IOptionWithId } from 'common/interfaces/IOption'
import { UtilService } from 'common/services/utilService'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'
import type { IMediaFile } from 'features/Home/interfaces/IMediaFile'

import type { DepositData } from 'features/Profile/features/Deposit/type'
import type { ISettingBilling } from 'features/SettingsOld/interfaces/ISettingBilling'
import type { ISettingHistory } from 'features/SettingsOld/interfaces/ISettingHistory'
import type { ISettingPaymentCard } from 'features/SettingsOld/interfaces/ISettingPaymentCard'
import type { Credentials, ProfileNotifications } from 'features/SettingsOld/interfaces/ISettings'
import type { ISettingsProfile } from 'features/SettingsOld/interfaces/ISettingsProfile'

const { withPaginationParams } = UtilService

export const settingsApi = createApi({
  reducerPath: REDUCERS.SETTINGS,
  tagTypes: ['Profile', 'Methods', 'Billing', 'MailboxProfile', 'Media'],
  baseQuery: baseQuery(),

  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchProfile: build.query<ISettingsProfile, void>({
      query: () => ({
        url: '/profile',
      }),
      providesTags: ['profile'],
      transformResponse: (response: ISettingsProfile<IOptionWithId>): ISettingsProfile => ({
        ...response,
        countries: response.countries.map(
          (country: IOptionWithId): IOption => ({ code: country.id, name: country.name }),
        ),
      }),
    }),

    fetchNotifications: build.query<ProfileNotifications, void>({
      query: () => ({
        url: '/profile/notifications',
      }),
      providesTags: ['profileNotifications'],
    }),

    updatePassword: build.mutation({
      query: (data: Credentials) => ({
        data,
        url: '/profile/credentials',
        method: HTTP_METHODS.POST,
      }),
    }),

    updateImage: build.mutation({
      query: (data) => ({
        data,
        url: '/profile-image',
        method: HTTP_METHODS.POST,
      }),
      invalidatesTags: ['profile'],
    }),

    deleteImage: build.mutation({
      query: (id) => ({
        url: '/profile-image/' + id,
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: ['profile'],
    }),
    updateProfile: build.mutation({
      query: (data) => ({
        data,
        url: '/profile',
        method: HTTP_METHODS.POST,
      }),
      invalidatesTags: ['profile'],
    }),

    updateNotifications: build.mutation({
      query: (data: ProfileNotifications) => ({
        data,
        url: '/profile/notifications',
        method: HTTP_METHODS.POST,
      }),
      invalidatesTags: ['profileNotifications'],
    }),

    fetchMailboxProfile: build.query({
      query: () => ({
        url: '/mailbox-profile',
      }),
      providesTags: ['MailboxProfile'],
    }),
    updateMailboxProfile: build.mutation({
      query: (data) => ({
        data,
        url: '/mailbox-profile',
        method: HTTP_METHODS.POST,
      }),
      invalidatesTags: ['MailboxProfile'],
    }),
    uploadImage: build.mutation({
      query: (file) => ({
        data: { file },
        url: '/file-upload',
        method: HTTP_METHODS.POST,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),

    fetchBilling: build.query({
      query: () => ({
        url: '/billing-address',
        method: HTTP_METHODS.GET,
      }),
      providesTags: ['Billing'],
      transformResponse: (data: ISettingBilling | []) => (Array.isArray(data) ? {} : data),
    }),

    fetchCardDetails: build.query({
      query: (paymentMethodId) => ({
        url: `/payment/customer/edit-card?paymentMethodId=${paymentMethodId}`,
      }),
    }),

    updateCard: build.mutation({
      query: (data) => ({
        data,
        method: HTTP_METHODS.POST,
        url: '/payment/customer/edit-card',
      }),
    }),

    fetchPaymentMethods: build.query<
      {
        default: string
        paymentMethods: ISettingPaymentCard[]
      },
      void
    >({
      query: () => ({
        url: '/payment/customer/payment-methods',
      }),
      providesTags: ['Methods'],
    }),
    createPaymentMethod: build.mutation({
      query: (data) => ({
        data,
        method: HTTP_METHODS.POST,
        url: '/payment/customer/add-card',
      }),
      invalidatesTags: ['Methods'],
    }),
    changeDefaultPaymentMethod: build.mutation({
      query: (paymentMethodId) => ({
        data: { paymentMethodId },
        method: HTTP_METHODS.POST,
        url: '/payment/customer/change-card',
      }),
    }),
    deletePaymentMethod: build.mutation({
      query: (paymentMethodId) => ({
        data: { paymentMethodId },
        method: HTTP_METHODS.POST,
        url: '/payment/customer/remove-card',
      }),
      invalidatesTags: ['Methods'],
    }),

    billingSites: build.query({
      query: () => ({
        url: `billing-history-sites`,
      }),
      transformResponse: ({ sites }: { sites: IOption[] }): IOption[] => sites,
    }),

    billingSiteHistory: build.query({
      query: ({ site, tableParams, bookingId }) => {
        let url = withPaginationParams('billing-history', tableParams)
        if (bookingId) {
          const new_params = new URLSearchParams({
            filter: bookingId,
          })
          url += `&${new_params.toString()}`
        }
        return {
          url,
          headers: { 'Database-Connection': site },
        }
      },
    }),

    paymentHistory: build.query({
      query: ({ site, tableParams }) => ({
        url: withPaginationParams('payment-history', tableParams),
        headers: { 'Database-Connection': site },
      }),
    }),

    downloadInvoice: build.query({
      query: ({ lease_id, db_connection, id }: ISettingHistory) => ({
        url: `leases/${lease_id}/download/invoice/${id}`,
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
          'Database-Connection': db_connection,
          'Content-Type': 'application/pdf',
        },
      }),
    }),

    fetchDeposit: build.query<DepositData, void>({
      query: () => ({
        url: '/deposit',
        method: HTTP_METHODS.GET,
      }),
      providesTags: ['Deposit'],
    }),

    requestDepositRefund: build.mutation({
      query: () => ({
        url: '/request-deposit-refund',
        method: HTTP_METHODS.POST,
      }),
      invalidatesTags: ['Deposit'],
    }),
    getMedia: build.query<{ display_1: IMediaFile[]; display_2: IMediaFile[] }, string>({
      query: () => ({
        url: 'media',
      }),
      providesTags: ['Media'],
    }),
    updateMedia: build.mutation<
      {
        display_1: IMediaFile[]
        display_2: IMediaFile[]
      },
      { data: IMediaUpload[]; siteId: string }
    >({
      query: ({ data, siteId }) => ({
        url: 'media',
        data,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId, 'Content-Type': 'multipart/form-data' },
      }),
      invalidatesTags: ['Media'],
    }),
    removeMedia: build.mutation<
      {
        display_1: IMediaFile[]
        display_2: IMediaFile[]
      },
      { mediaId: string; siteId: string }
    >({
      query: ({ mediaId, siteId }) => ({
        url: `media/${mediaId}`,
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': siteId },
      }),
      invalidatesTags: ['Media'],
    }),
  }),
})

export const {
  useFetchMailboxProfileQuery,
  useUpdateMailboxProfileMutation,
  useUpdateCardMutation,
  useLazyBillingSiteHistoryQuery,
  useLazyPaymentHistoryQuery,
  useLazyFetchCardDetailsQuery,
  useLazyDownloadInvoiceQuery,
  useChangeDefaultPaymentMethodMutation,
  useFetchDepositQuery,
  useRequestDepositRefundMutation,
}: any = settingsApi

export const {
  useUpdateProfileMutation,
  useFetchProfileQuery,
  useFetchNotificationsQuery,
  useUpdateNotificationsMutation,
  useUploadImageMutation,
  useUpdateImageMutation,
  useDeleteImageMutation,
  useLazyFetchPaymentMethodsQuery,
  useCreatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useUpdatePasswordMutation,
  useGetMediaQuery,
  useUpdateMediaMutation,
  useRemoveMediaMutation,
} = settingsApi
