import * as Yup from 'yup'
import { RegexService } from 'common/services/regexService'
import { SETTINGS_PROFILE_KEYS } from 'features/SettingsOld/constants/settingsProfileKeys'

const passwordValidationSchema = Yup.string().test(
  'password-validation',
  'Invalid password',
  function (value: string) {
    const { path, createError } = this
    if (value) {
      switch (true) {
        case !RegexService.includeLowerCase().test(value):
          return createError({ path, message: 'Password must include one lowercase character' })
        case !RegexService.number().test(value):
          return createError({ path, message: 'Password must include one digit' })
        case !RegexService.includeSpecialChar().test(value):
          return createError({ path, message: 'Password must include special character' })
        case value.length < 8:
          return createError({ path, message: 'Password must include minimum 8 characters' })
        default:
          return true
      }
    }
    return true
  },
)

export const changePasswordSchema = Yup.object().shape({
  [SETTINGS_PROFILE_KEYS.CURRENT_PASSWORD]: Yup.string().required('Current password is required'),
  [SETTINGS_PROFILE_KEYS.NEW_PASSWORD]: passwordValidationSchema
    .notOneOf(
      [Yup.ref(SETTINGS_PROFILE_KEYS.CURRENT_PASSWORD)],
      'New password must be different from the current password',
    )
    .required('New password is required'),
  [SETTINGS_PROFILE_KEYS.NEW_CONFIRM_PASSWORD]: Yup.string()
    .oneOf([Yup.ref(SETTINGS_PROFILE_KEYS.NEW_PASSWORD)], 'Passwords must match')
    .required('Confirm password is required'),
})
