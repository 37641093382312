import type { FC } from 'react'
import { useMemo, useState } from 'react'
import classNames from 'classnames'

import { Show } from 'common/components/Show/Show'
import { UtilService } from 'common/services/utilService'

import { useCartManager, type ICartTaxes } from 'features/Cart'
import { PaymentPriceItem } from 'features/Payment/components/PaymentPriceItem/PaymentPriceItem'
import { PAYMENT_CONSTANTS } from 'features/Payment/constants/paymentConstants'

import styles from './paymentPrice.module.scss'
import { BOOKING_TYPE_CODE } from 'features/Booking/constants/booking.constants'
import { Checkbox, type CheckboxProps } from 'antd'

interface IProps {
  limit: number
  isLeaseShop?: boolean
  amount: ICartTaxes
  className?: string
  totalLabel?: string
  nextAmount?: number
  nextAmountLabel?: string
  hasHvacOnThisBooking?: boolean
  hasHvacActivated?: boolean
  onChangeHvac?: (checked: boolean) => void
}

export const PaymentPrice: FC<IProps> = ({
  limit,
  amount = {},
  isLeaseShop,
  className,
  totalLabel = PAYMENT_CONSTANTS.TOTAL,
  nextAmount,
  nextAmountLabel,
  hasHvacOnThisBooking = false,
  hasHvacActivated,
  onChangeHvac,
}) => {
  const { activeBooking } = useCartManager()
  const isEhrLease = activeBooking?.requestId === BOOKING_TYPE_CODE.EHR

  const handleChangeHvac: CheckboxProps['onChange'] = (e) => {
    onChangeHvac?.(e.target.checked)
  }

  const total = useMemo(() => {
    if (!amount) return 0

    if (!hasHvacOnThisBooking) return amount?.total

    return amount?.total - (!hasHvacActivated ? amount?.room_fees : 0)
  }, [JSON.stringify(amount), hasHvacActivated, hasHvacOnThisBooking])

  return (
    <div className={classNames(styles.parent, className)}>
      <hr />
      {hasHvacOnThisBooking && (
        <Checkbox
          disabled={!amount?.allow_hvac}
          checked={hasHvacActivated}
          onChange={handleChangeHvac}
          className={styles.hvacCheckbox}>
          <span className={styles.hvacLabel}>I request after hours HVAC</span>
        </Checkbox>
      )}
      <PaymentPriceItem
        title={PAYMENT_CONSTANTS.SUBTOTAL}
        price={(amount?.subtotal ?? 0) + (amount?.discount_amount ?? 0)}
      />
      {amount?.discount_amount > 0 && (
        <PaymentPriceItem title={PAYMENT_CONSTANTS.DISCOUNT} price={amount?.discount_amount || 0} />
      )}

      <PaymentPriceItem title={PAYMENT_CONSTANTS.TAXES_SALES} price={amount?.taxes || 0} />
      {amount?.fees > 0 && (
        <PaymentPriceItem
          title={isEhrLease ? PAYMENT_CONSTANTS.FEES_EHR : PAYMENT_CONSTANTS.FEES_INSURANCE}
          price={amount?.fees}
        />
      )}
      {amount?.room_fees > 0 && hasHvacActivated && (
        <PaymentPriceItem title={PAYMENT_CONSTANTS.FEES_AFTER_HOURS} price={amount?.room_fees} />
      )}
      {amount?.ehr_setup_fee > 0 && (
        <PaymentPriceItem title={PAYMENT_CONSTANTS.FEES_EHR} price={amount?.ehr_setup_fee} />
      )}

      <Show when={!isLeaseShop}>
        <PaymentPriceItem price={amount?.deposit} title={PAYMENT_CONSTANTS.DEPOSIT} />
      </Show>

      <hr />

      <PaymentPriceItem title={totalLabel} price={total} />

      {nextAmount && (
        <>
          <hr />
          <PaymentPriceItem title={nextAmountLabel} price={nextAmount} />
        </>
      )}

      {hasHvacOnThisBooking && (
        <p className={styles.hvacInfo}>
          after hours HVAC service incurs extra fees and is only available with one business day
          advance notice
        </p>
      )}

      <Show when={limit > 0 && isFinite(limit)}>
        <p className='cart-inform'>
          {PAYMENT_CONSTANTS.CURRENTLY}
          {UtilService.numberToDollar(limit)}
        </p>
      </Show>
    </div>
  )
}
