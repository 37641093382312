import './roomAvailability.scss'

import { type FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Spin } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'

import { Button } from 'common/components/Button/Button'
import { Modal } from 'common/components/Modal'
import Show from 'common/components/Show2/Show'
import { useAppDispatch } from 'common/hooks/redux'

import {
  useCreatePromotionRoomsBookingMutation,
  useLazyGetDayRoomsAvailabilityQuery,
} from 'features/Home/Promotion/state/promotionsApi'
import { updatePromotionLatestCrud } from 'features/Home/Promotion/state/promotionsSlice'
import type { RoomAvailabilityProps } from './roomAvailability.types'
import { RoomLoadingSkeleton } from './RoomLoadingSkeleton'
import { RoomBookingSlots } from './RoomBookingSlots'
import Popup from 'common/components/Popup'

export const RoomAvailability: FC<RoomAvailabilityProps> = ({
  date,
  conventionRooms,
  skeletonRooms = 1,
  open,
  handleCancel,
}) => {
  const dispatch = useAppDispatch()

  const { bookingId, siteId } = useParams()

  const [fetchRoomAvailability, { data, isFetching }] = useLazyGetDayRoomsAvailabilityQuery()

  const [createBooking] = useCreatePromotionRoomsBookingMutation()

  const { values, isSubmitting, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      slots: {},
    },
    onSubmit,
    enableReinitialize: true,
  })

  function onCancel() {
    resetForm()
    handleCancel()
  }

  async function onSubmit() {
    try {
      const data = Object.keys(values.slots).map((key) => {
        const roomId = Number(key)
        const conventionRoom = getConventionRoom(roomId)
        const selectedSlots = values.slots[key]
        const weekdaySlots = conventionRoom.weekday_slots

        // Transform slots into object with slot type (0 for weekend, 1 for weekday)
        const slots = selectedSlots.reduce((acc, slotId, index) => {
          // If it's weekend, all slots are weekend slots (0)
          // If it's weekday, slots within weekday limit are weekday slots (1), rest are weekend slots (0)
          // Previous validations ensure that we don't select more slots than available
          const isWeekdaySlot = !isWeekend && index < weekdaySlots
          acc[slotId] = isWeekdaySlot ? 1 : 0
          return acc
        }, {})

        return {
          id: roomId,
          slots,
        }
      })

      const response = await createBooking({
        bookingId,
        siteId,
        date,
        data,
      })

      dispatch(updatePromotionLatestCrud(response.data))
      onCancel()
    } catch (error) {}
  }

  const handleSlotToggle = (roomId: number, slotId: number) => {
    const roomsSlots = values.slots[roomId] ?? []
    const prevSelectedSlotIds = new Set(roomsSlots)
    if (prevSelectedSlotIds.has(slotId)) {
      prevSelectedSlotIds.delete(slotId)
    } else {
      prevSelectedSlotIds.add(slotId)
    }
    setFieldValue(`slots.${roomId}`, Array.from(prevSelectedSlotIds))
  }

  const handleGroupToggle = (roomId: number, groupSlotIds: number[]) => {
    const alreadySelected = values.slots[roomId] ?? []

    const hasAnySelected = groupSlotIds.some((slotId) => alreadySelected.includes(slotId))

    let finalSelectedSlots: number[]

    if (hasAnySelected) {
      // If any slot in the group is already selected, remove all groupSlotIds
      finalSelectedSlots = alreadySelected.filter((slotId) => !groupSlotIds.includes(slotId))
    } else {
      const conventionRoom = getConventionRoom(roomId)

      const weekdaySlots = conventionRoom.weekday_slots
      const weekendSlots = conventionRoom.weekend_slots

      const availableSlotsCount = isWeekend ? weekendSlots : weekdaySlots + weekendSlots

      const remainedSlotsCount = availableSlotsCount - alreadySelected.length

      const unselectedSlots = groupSlotIds.filter((slotId) => !alreadySelected.includes(slotId))
      const additionalSlotsCount = Math.max(remainedSlotsCount, 0)

      finalSelectedSlots = [...alreadySelected, ...unselectedSlots.slice(0, additionalSlotsCount)]
    }

    setFieldValue(`slots.${roomId}`, finalSelectedSlots)
  }

  const getConventionRoom = (roomId: number) => {
    return conventionRooms.find((room) => room.id === roomId)!
  }

  const hasSelectedSlot = Object.keys(values.slots).some((key) => values.slots[key]?.length)

  useEffect(() => {
    if (open && date) {
      fetchRoomAvailability({
        bookingId,
        siteId,
        date,
      })
    }
  }, [open])

  function getIsWeekend() {
    const selectedDate = dayjs(date).startOf('day')
    const dayOfWeek = selectedDate.day()
    return dayOfWeek === 0 || dayOfWeek === 6
  }

  const isWeekend = getIsWeekend()

  const checkHasReachedMaxSlots = (roomId: number) => {
    const conventionRoom = getConventionRoom(roomId)

    if (!conventionRoom) {
      return false
    }

    const weekendSlots = conventionRoom.weekend_slots
    const weekdaySlots = conventionRoom.weekday_slots

    // we allow to use weekend slots for weekday bookings, since weekend is more expensive
    const slots = isWeekend ? weekendSlots : weekdaySlots + weekendSlots
    return slots <= values.slots[roomId]?.length
  }

  const getDefaultSelectedSlots = (roomId: number) => {
    return values.slots[roomId] ?? []
  }

  function getHasRoomsUsedWeekendSlots() {
    return Object.keys(values.slots).some((roomId) => {
      const conventionRoom = getConventionRoom(Number(roomId))
      const selectedSlotsCount = values.slots[roomId]?.length || 0
      return selectedSlotsCount > conventionRoom.weekday_slots
    })
  }

  const onSubmitData = () => {
    const hasUsedWeekendSlots = !isWeekend && getHasRoomsUsedWeekendSlots()

    if (hasUsedWeekendSlots) {
      Popup.showInfo(
        {
          title: 'Alert',
          message:
            'You are using weekend slots (more expensive) to book week day slots (less expensive).',
          mainButtonText: 'Book',
          secondButtonText: 'Cancel',
        },
        () => handleSubmit(),
      )
    } else {
      handleSubmit()
    }
  }

  return (
    <Modal
      wrapClassName='promotion-room-availability-wrapper'
      // destroyOnClose
      onCancel={onCancel}
      open={open}
      title='I would like to book'>
      <Spin spinning={isSubmitting}>
        <div className='promotion-room-availability'>
          <Show>
            <Show.When isVisible={isFetching}>
              <RoomLoadingSkeleton skeletonRooms={skeletonRooms} />
            </Show.When>
            <Show.Else>
              {data?.map((room) => {
                return (
                  <RoomBookingSlots
                    key={room.id}
                    room={room}
                    handleSlotToggle={handleSlotToggle}
                    handleGroupToggle={handleGroupToggle}
                    selectedSlotIds={getDefaultSelectedSlots(room.id)}
                    checkHasReachedMaxSlots={() => checkHasReachedMaxSlots(room.id)}
                  />
                )
              })}
              <div className='footer-container'>
                <Button
                  disabled={isSubmitting || !hasSelectedSlot}
                  htmlType='submit'
                  onClick={onSubmitData}>
                  Add
                </Button>
              </div>
            </Show.Else>
          </Show>
        </div>
      </Spin>
    </Modal>
  )
}
