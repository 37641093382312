import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { UtilService } from 'common/services/utilService'

import type { IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import type { ICartTaxes } from 'features/Cart'
import type {
  IGetRoomByLeaseIdProps,
  IGetUpdatedShopItemsProps,
  IGetUpdateRoomSlotProps,
  IRemoveSlotFromShopItemsProps,
} from 'features/Option/interfaces/IOptionService'
import type {
  IOptionShopRoomSelected,
  ISlotExtensionRoom,
} from 'features/Option/interfaces/IOptionShop'
import type {
  ICalculateActivePriceProps,
  IGetIsDisabledSubmitProps,
  IOptionShopCartItem,
  IOptionShopCartOption,
  IOptionShopCartSlotExtension,
} from 'features/Option/interfaces/IOptionShopCartItem'

const { structuredClone, arrDeepEqual } = UtilService

const findLeaseById = (shopItems: IOptionShopCartItem[] | undefined, bookingId: string) =>
  shopItems?.find((shop: IOptionShopCartItem): boolean => shop.bookingId === bookingId)

const findOptionById = (options: IOptionShopCartOption[] | undefined, optionId: number) =>
  options?.find((rOption: IOptionShopCartOption) => rOption.option.id === optionId)

const findRoomByCode = (rooms: IOptionShopRoomSelected[], roomCode: number) =>
  rooms.find((rRoom: IOptionShopRoomSelected) => rRoom.room.code === roomCode)

const updateRoom = (rooms: IOptionShopRoomSelected[], room: IOptionShopRoomSelected) =>
  rooms
    .map((rRoom: IOptionShopRoomSelected) => (rRoom.room.code === room.room.code ? room : rRoom))
    .filter((rRoom: IOptionShopRoomSelected) => !!rRoom.selectedSlots.length)

const updateOption = (
  options: IOptionShopCartOption[],
  option: ISiteRoom,
  room: IOptionShopRoomSelected,
) =>
  options
    .map((rOption: IOptionShopCartOption) =>
      rOption.option.id === option.id
        ? {
            ...rOption,
            rooms: findRoomByCode(rOption.rooms, room.room.code as number)
              ? updateRoom(rOption.rooms, room)
              : [...rOption.rooms, room],
          }
        : rOption,
    )
    .filter(
      (rOption: IOptionShopCartOption) => !!rOption.rooms.length || !!rOption.slot || !!rOption.day,
    )

const calculateOptionExtensionPrice = (option: IOptionShopCartSlotExtension | null): number =>
  option ? option.rooms.reduce((acc: number, room: ISlotExtensionRoom) => acc + room.price, 0) : 0

const updateRoomsPrice = (rooms: IOptionShopRoomSelected[]) => {
  let roomsAmount: number = 0
  const updatedRooms: IOptionShopRoomSelected[] = rooms.map((room: IOptionShopRoomSelected) => {
    let roomAmount: number = 0
    const selectedSlots = room.selectedSlots.map((slot: IRoomTimeSlot) => {
      const quantity = slot.quantity === null ? 1 : slot.quantity
      const amount: number = slot.price * quantity
      roomAmount += amount
      return {
        ...slot,
        quantity,
      }
    })
    roomsAmount += roomAmount

    return {
      ...room,
      amount: roomAmount,
      selectedSlots,
    }
  })

  return { roomsAmount, updatedRooms }
}

const updateOptionsPrice = (options: IOptionShopCartOption[]) => {
  let optionsAmount: number = 0
  const updatedOptions = options?.map((option: IOptionShopCartOption) => {
    const { roomsAmount, updatedRooms } = updateRoomsPrice(option?.rooms ?? [])
    optionsAmount += roomsAmount
    return {
      ...option,
      amount: roomsAmount,
      rooms: updatedRooms,
    }
  })

  return { optionsAmount, updatedOptions }
}

const updateShopItemsAddPrice = (shopItems: IOptionShopCartItem[]): IOptionShopCartItem[] =>
  shopItems.map((item: IOptionShopCartItem) => {
    let shopAmount: number = 0
    const { optionsAmount, updatedOptions } = updateOptionsPrice(item?.options)
    shopAmount += optionsAmount
    shopAmount += calculateOptionExtensionPrice(item.optionSlotExtension)
    return {
      ...item,
      amount: {
        subtotal: shopAmount,
      },
      options: updatedOptions,
    }
  })

const getUpdatedShopItems = ({
  bookingId,
  option,
  room,
  shopItems,
  siteId,
}: IGetUpdatedShopItemsProps) => {
  if (!shopItems.length)
    return updateShopItemsAddPrice([{ bookingId, siteId, options: [{ option, rooms: [room] }] }])
  const reservedLease = findLeaseById(shopItems, bookingId)

  if (!reservedLease)
    return updateShopItemsAddPrice([
      ...shopItems,
      { bookingId, siteId, options: [{ option, rooms: [room] }] },
    ])

  const reservedOption = findOptionById(reservedLease.options, option.id)

  if (!reservedOption)
    return updateShopItemsAddPrice(
      shopItems.map((leaseShop) =>
        leaseShop.bookingId === bookingId
          ? { ...leaseShop, options: [...(leaseShop?.options || []), { option, rooms: [room] }] }
          : leaseShop,
      ),
    )
  // NO IDEA WHAT'S HERE, ASK VADYM, I just fixed a bug
  const mappedShopItems = shopItems.map((leaseShop: IOptionShopCartItem) => {
    return leaseShop.bookingId === bookingId
      ? { ...leaseShop, options: updateOption(leaseShop.options, option, room) }
      : leaseShop
  })
  const filteredShopItems = mappedShopItems.filter(
    ({ options, optionSlotExtension }: IOptionShopCartItem) => {
      return (
        options?.length &&
        (options.some((opt) => opt.rooms?.length || opt.slot) || // Check for rooms or slots
          optionSlotExtension?.rooms?.length)
      )
    },
  )

  return updateShopItemsAddPrice(filteredShopItems)
}

const getRoomByLeaseId = ({ bookingId, option, roomId, shopItems }: IGetRoomByLeaseIdProps) => {
  const reservedLease = findLeaseById(shopItems, bookingId)
  if (reservedLease?.options)
    return reservedLease.options
      .find((rLease: { option: ISiteRoom }): boolean => rLease.option.id === option.id)
      ?.rooms.find((rRoom: IOptionShopRoomSelected): boolean => rRoom.room.code === roomId)

  return null
}

const getUpdateRoomSlot = ({ slot, room, ...rest }: IGetUpdateRoomSlotProps) => {
  const selectedSlots = room.selectedSlots.map(
    (rSlot: IRoomTimeSlot): IRoomTimeSlot => (rSlot.id === slot.id ? slot : rSlot),
  )
  const updatedRoom = { ...room, selectedSlots }
  return {
    room: updatedRoom,
    items: getUpdatedShopItems({ room: updatedRoom, ...rest }),
  }
}

const getPriceSpecificLease = (
  shopsItems: IOptionShopCartItem[],
  bookingId: string,
): ICartTaxes => {
  const reservedLease = findLeaseById(shopsItems, bookingId)
  if (reservedLease) return reservedLease.amount
  return { subtotal: 0 }
}

const getPriceSpecificOption = (
  shopsItems: IOptionShopCartItem[],
  bookingId: string,
  optionId: number,
) => {
  const reservedLease = findLeaseById(shopsItems, bookingId)
  if (reservedLease) {
    const reservedOption = findOptionById(reservedLease.options, optionId)
    if (reservedOption) return reservedOption.amount
  }
  return 0
}

const cleanupShopItems = (shopItems: IOptionShopCartItem[]): IOptionShopCartItem[] =>
  shopItems
    .filter(
      (lease: IOptionShopCartItem): boolean =>
        !!lease?.options?.length ||
        !!lease.optionSlotExtension?.rooms?.length ||
        !!lease.options?.some((option: IOptionShopCartOption) => option.day || option.slot),
    )
    .map(
      (lease: IOptionShopCartItem): IOptionShopCartItem => ({
        ...lease,
        optionSlotExtension: !lease.optionSlotExtension?.rooms.length
          ? null
          : lease.optionSlotExtension,
      }),
    )

const removeSlotFromShopItems = ({
  shopItems,
  bookingId,
  slotId,
  roomId,
  optionId,
  day,
}: IRemoveSlotFromShopItemsProps) => {
  const copiedShopItems: IOptionShopCartItem[] = structuredClone<IOptionShopCartItem[]>(shopItems)
  const reservedLease: IOptionShopCartItem = findLeaseById(copiedShopItems, bookingId)
  const reservedOption: IOptionShopCartOption = findOptionById(reservedLease.options, optionId)

  // Handle direct slot in option
  if (day) {
    if (reservedOption?.day?.date === day) {
      delete reservedOption.day
    }
  } else if (reservedOption?.slot?.id === slotId) {
    delete reservedOption.slot
  } else if (reservedOption?.rooms) {
    // Handle slot in rooms array
    const reservedRoom: IOptionShopRoomSelected = findRoomByCode(reservedOption.rooms, roomId)
    if (reservedRoom) {
      reservedRoom.selectedSlots = reservedRoom.selectedSlots.filter(
        (slot: IRoomTimeSlot): boolean => slot.id !== slotId,
      )
      // Clean up rooms
      reservedOption.rooms = reservedOption.rooms.filter(
        (room: IOptionShopRoomSelected): boolean => !!room.selectedSlots.length,
      )
    }
  }

  // Clean up options
  reservedLease.options = reservedLease.options.filter(
    (option: IOptionShopCartOption): boolean =>
      !!(option.rooms?.length || option.slot || option.day),
  )

  return updateShopItemsAddPrice(cleanupShopItems(copiedShopItems))
}

const getIsDisabledSubmit = ({
  initialOption,
  reservedOption,
}: IGetIsDisabledSubmitProps): boolean => {
  if (!initialOption?.options && !reservedOption?.options) return true
  return arrDeepEqual(initialOption?.options, reservedOption?.options)
}

const calculateActivePrice = ({ shopOption }: ICalculateActivePriceProps): number => {
  if (shopOption?.selectedSlot) {
    const { selectedSlot, quantity } = shopOption
    return selectedSlot?.price * (quantity || 1)
  }

  if (shopOption?.selectedDay) {
    const { selectedDay } = shopOption
    return selectedDay?.price || 0
  }

  return 0
}

export const OptionShopService = {
  getUpdatedShopItems,
  getRoomByLeaseId,
  findOptionById,
  getUpdateRoomSlot,
  findLeaseById,
  cleanupShopItems,
  getIsDisabledSubmit,
  calculateActivePrice,
  getPriceSpecificOption,
  getPriceSpecificLease,
  removeSlotFromShopItems,
}
