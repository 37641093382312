export enum PATH_SEGMENT {
  ADMIN = 'admin',
  AUTH = 'auth',
  BILLING = 'billing',
  BILLING_HISTORY = 'billing-history',
  BOOKING = 'booking',
  COMPLETED_BOOKINGS = 'completed-bookings',
  CONTRACTS = 'contracts',
  CHANGE_PASSWORD = 'change-password',
  CHECKOUT = 'checkout',
  CREDIT_CARDS = 'credit-cards',
  DEPOSIT = 'deposit',
  DETAILS = 'details',
  DICTIONARY = 'dictionary',
  ERROR = 'error',
  FAQ = 'faq',
  GUEST = 'guest',
  HOME = 'home',
  LOGIN = 'login',
  MAILBOX_PROFILE = 'mailbox-profile',
  BOOK = 'book',
  NOTIFICATIONS = 'notifications',
  PREFERENCES = 'preferences',
  PATIENTS = 'patients',
  SCHEDULE = 'schedule',
  DOCUMENTS = 'documents',
  SHOP = 'shop',
  ROOMS = 'rooms',
  OTHERS = 'others',
  PAYMENTS_HISTORY = 'payments-history',
  PERSONAL_PROFILE = 'personal-profile',
  PROFILE = 'profile',
  PACKAGE = 'package',
  PACKAGES = 'packages',
  PROVIDER = 'provider',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset-password',
  ROOT = '/',
  SETTINGS = 'settings',
  SET_NEW_PASSWORD = 'set-new-password',
  STAFF = 'staff',
  DOCTORS = 'doctors',
  START_VALIDATION_PROCESS = 'start-processing-documents',
  SUPPORT = 'support',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  TICKET_DETAILS = 'ticket-details',
  VALIDATE_EMAIL = 'validate-email',
  VALIDATE_IDENTITY = 'validate-doctor-credentials',
  VALIDATION_PROCESSING = 'status-processing-documents',
  VALIDATION_RESULT = 'processing-result',
  MEDIA_PREFERENCES = 'media-preferences',
  OTHER = '*',
}
