import styles from './announce.module.scss'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AnnounceButton } from './announce'
import { useAnnounceRoomMutation } from 'features/Home/Book/state/api/bookApi'
import { Input } from 'common/components/Input/Input'
import { Textarea } from 'common/components/Textarea/Textarea'
import { Modal } from 'common/components/Modal'
import { type ApiResponse, useApiResponse } from 'common/hooks/useApiResponse'
import { useParams } from 'react-router-dom'

type Props = {
  open: boolean
  toggleOpen: () => void
  roomId: number
}

const schema = yup.object().shape({
  roomId: yup.number(),
  message: yup.string().required('Message is required'),
  dings: yup.number().min(0, 'Ding cannot be less than 0').required('Dings is required'),
})

export const AnnounceModal = ({ open, toggleOpen, roomId }: Props) => {
  const { siteId } = useParams()
  const [handleAnnounce, { isLoading: isAnnouncing }] = useAnnounceRoomMutation()

  const { processApiResponse } = useApiResponse()

  const { values, isValid, dirty, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: {
      roomId,
      message: '',
      dings: 1,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit,
  })

  async function onSubmit() {
    const res = await handleAnnounce({
      message: values.message,
      number_of_dings: values.dings,
      roomId: values.roomId,
      siteId,
    })
    processApiResponse(res as ApiResponse, {
      description: 'Announcement sent successfully',
      error: 'Announcement failed',
      successCallback: () => {
        toggleOpen()
      },
    })
  }

  return (
    <Modal
      centered
      classNames={{ content: styles.announceModal }}
      footer={null}
      destroyOnClose
      title='Announce'
      keyboard
      open={open}
      onCancel={toggleOpen}>
      <form className={styles.announceForm} onSubmit={handleSubmit}>
        <>
          <Textarea
            disabled={isAnnouncing}
            label='Message'
            required
            rows={5}
            className='block'
            value={values.message}
            name='message'
            onChange={(e) => setFieldValue('message', e.target.value)}
            placeholder='Message'
          />
          <Input
            disabled={isAnnouncing}
            required
            min={0}
            type='number'
            label='Dings'
            name='dings'
            value={values.dings}
            onChange={handleChange}
            placeholder='Type here'
          />
          <div className={styles.announceFormButtonContainer}>
            <AnnounceButton
              loading={isAnnouncing}
              disabled={!isValid || !dirty}
              htmlType='submit'
              buttonLabel='Announce'
            />
          </div>
        </>
      </form>
    </Modal>
  )
}
