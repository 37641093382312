import type { FC } from 'react'
import { memo } from 'react'
import { useToggle } from 'react-use'

import { Tooltip } from 'antd'
import classNames from 'classnames'

import openValue from 'assets/icons/eye.svg'
import details from 'assets/icons/info-black.svg'
import closeValue from 'features/Home/assets/EyeSlash.svg'
import reset from 'features/Home/assets/reload.svg'
import resend from 'features/Home/assets/send.svg'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_MODIFIER } from 'common/constants/buttonConstants'

import styles from './sectionItem.module.scss'
import type { SectionItemProps } from './sectionItem.types'
import LinkWrapper from 'common/components/LinkWrapper/LinkWrapper'

export const SectionItem: FC<SectionItemProps> = memo(
  ({ className, isBookingActive, type, value, handleReset, handleResend, field }) => {
    const [isOpen, toggleIsOpen] = useToggle(false)
    return (
      <div className={classNames(styles.parent, className)}>
        <span className={styles.parentContent}>{field}</span>
        <span className={styles.parentContent}>
          <Show when={!isOpen && type} fallback={<LinkWrapper text={value as string} />}>
            <Show when={type === 'secret'}>
              <span className={styles.parentSecretContent}>***</span>
            </Show>
          </Show>

          <Show when={type === 'secret'}>
            <Button className='p-0' modifier={BUTTON_MODIFIER.DEFAULT} onClick={toggleIsOpen}>
              <img
                alt={ALT_CONSTANTS.ACTION_ICON}
                className={styles.parentStatusIcon}
                src={isOpen ? closeValue : openValue}
              />
            </Button>
          </Show>

          <Show when={handleResend}>
            <Button
              disabled={!isBookingActive}
              onClick={handleResend}
              className='reset-button'
              modifier={BUTTON_MODIFIER.DEFAULT}>
              <Tooltip title='Click to resend access code'>
                <img
                  src={resend}
                  alt={ALT_CONSTANTS.ACTION_ICON}
                  className={styles.parentStatusIcon}
                />
              </Tooltip>
            </Button>
          </Show>

          {/* <Show when={handleReset}>
            <Button
              disabled={!isBookingActive}
              onClick={handleReset}
              className='reset-button'
              modifier={BUTTON_MODIFIER.DEFAULT}>
              <Tooltip title='Click to reset access code' placement='topRight'>
                <img
                  src={reset}
                  alt={ALT_CONSTANTS.ACTION_ICON}
                  className={styles.parentStatusIcon}
                />
              </Tooltip>
            </Button>
          </Show> */}

          <Show when={type === 'map'}>
            <a
              target='_blank'
              className='navigation-link'
              href={`https://www.google.com/maps/search/${value}`}
              rel='noreferrer'>
              View on map
            </a>
          </Show>

          <Show when={type === 'info'}>
            <Tooltip title={value} placement='left'>
              <img src={details} alt={ALT_CONSTANTS.INFO_ICON} />
            </Tooltip>
          </Show>
        </span>
      </div>
    )
  },
)
