import { memo } from 'react'

import { Col, Row } from 'antd'
import { Form, Formik } from 'formik'

import { useAuth } from 'app/providers/auth/AuthProvider'

import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'

import { SETTINGS_PROFILE_KEYS } from 'features/SettingsOld/constants/settingsProfileKeys'
import { SETTINGS_PROFILE_LABELS } from 'features/SettingsOld/constants/settingsProfileLabels'

import styles from './preferences.module.scss'
import {
  useFetchNotificationsQuery,
  useUpdateNotificationsMutation,
} from 'features/SettingsOld/state/api/settingsApi'
import { SaveButton } from 'features/Profile/components/SaveButton/SaveButton'
import type { ProfileNotifications } from 'features/SettingsOld/interfaces/ISettings'
import { useApiResponse } from 'common/hooks/useApiResponse'

export const Preferences = memo(() => {
  const { isDoctor } = useAuth()
  const { processApiResponse } = useApiResponse()
  const { data, isFetching } = useFetchNotificationsQuery()
  const [updateNotifications] = useUpdateNotificationsMutation()

  const handleSubmit = async (values: ProfileNotifications) => {
    const data = Object.entries(values).reduce((acc, [key, value]) => {
      acc[key] = Number(value)
      return acc
    }, {})

    const response = await updateNotifications(data as ProfileNotifications)
    processApiResponse(response, {
      error: 'Update notifications error',
      success: 'Update notifications success',
    })
  }

  if (!isDoctor) {
    return null
  }

  const {
    lease_notification,
    ticket_notification,
    patient_cancel_notification,
    patient_confirm_notification,
    patient_reschedule_notification,
    end_lease_notification,
    accept_early_patient_check_in,
    accept_walk_in_patient,
  } = data ?? {}

  return (
    <Formik
      enableReinitialize
      initialValues={{
        lease_notification,
        ticket_notification,
        patient_cancel_notification,
        patient_confirm_notification,
        patient_reschedule_notification,
        end_lease_notification,
        accept_early_patient_check_in,
        accept_walk_in_patient,
      }}
      onSubmit={handleSubmit}>
      {({ isSubmitting, dirty }) => (
        <Form>
          <h1 className={styles.sectionTitle}>Notifications</h1>
          <Row gutter={[32, 32]}>
            <Col xs={24} md={12}>
              <h2 className={styles.containerName}>
                {SETTINGS_PROFILE_LABELS.SCHEDULE_NOTIFICATIONS}
              </h2>
              <p className={styles.containerLabel}>
                {SETTINGS_PROFILE_LABELS.SCHEDULE_NOTIFICATIONS_LABEL}
              </p>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <FormControl
                    isLoading={isFetching}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SWITCH}
                    name={SETTINGS_PROFILE_KEYS.PATIENT_CONFIRMATION}
                    label={SETTINGS_PROFILE_LABELS.PATIENT_CONFIRMATION}
                  />
                </Col>
                <Col xs={24}>
                  <FormControl
                    isLoading={isFetching}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SWITCH}
                    name={SETTINGS_PROFILE_KEYS.PATIENT_CANCELATION}
                    label={SETTINGS_PROFILE_LABELS.PATIENT_CANCELATION}
                  />
                </Col>
                <Col xs={24}>
                  <FormControl
                    isLoading={isFetching}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SWITCH}
                    name={SETTINGS_PROFILE_KEYS.PATIENT_RESCHEDULE}
                    label={SETTINGS_PROFILE_LABELS.PATIENT_RESCHEDULE}
                  />
                </Col>
                <Col xs={24}>
                  <FormControl
                    isLoading={isFetching}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SWITCH}
                    name={SETTINGS_PROFILE_KEYS.END_OF_BOOKING}
                    label={SETTINGS_PROFILE_LABELS.END_OF_BOOKING}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <h2 className={styles.containerName}>
                {SETTINGS_PROFILE_LABELS.BOOKING_NOTIFICATIONS}
              </h2>
              <p className={styles.containerLabel}>
                {SETTINGS_PROFILE_LABELS.EMAIL_NOTIFICATIONS_LABEL}
              </p>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <FormControl
                    isLoading={isFetching}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SWITCH}
                    name={SETTINGS_PROFILE_KEYS.LEASE_NOTIFICATION}
                    label={SETTINGS_PROFILE_LABELS.RELATED_NOTIFICATIONS}
                  />
                </Col>
                <Col xs={24}>
                  <FormControl
                    isLoading={isFetching}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SWITCH}
                    name={SETTINGS_PROFILE_KEYS.TICKET_NOTIFICATION}
                    label={SETTINGS_PROFILE_LABELS.STATUS_NOTIFICATIONS}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <h1 className={styles.sectionTitle}>Schedule</h1>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <FormControl
                    isLoading={isFetching}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SWITCH}
                    name={SETTINGS_PROFILE_KEYS.ACCEPT_WALK_IN_PATIENT}
                    label={SETTINGS_PROFILE_LABELS.ACCEPT_WALK_IN_PATIENT}
                  />
                </Col>
                {/* <Col xs={24}>
                  <FormControl
                    isLoading={isFetching}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SWITCH}
                    name={SETTINGS_PROFILE_KEYS.ACCEPT_EARLY_PATIENT_CHECK_IN}
                    label={SETTINGS_PROFILE_LABELS.ACCEPT_EARLY_PATIENT_CHECK_IN}
                  />
                </Col> */}
              </Row>
            </Col>
          </Row>

          <Row>
            <SaveButton
              disabled={!dirty}
              className={styles.formSubmit}
              loading={isFetching || isSubmitting}
            />
          </Row>
        </Form>
      )}
    </Formik>
  )
})
