import { Col, Row } from 'antd'

import { ReactComponent as RemoveIcon } from 'features/Cart/assets/trash.svg'
import { useMemo } from 'react'
import type { PromotionSlotRoom } from 'features/Home/Promotion/types/promotion.types'
import dayjs from 'dayjs'

type RoomDetailsType = {
  id: number
  name: string
  timeSlot: string
  date: string
  status?: string
}

type Props = {
  room: RoomDetailsType
  isDeleteDisabled?: boolean
  hasError?: boolean
  handleDeleteBooking: () => void
  roomsToDelete: PromotionSlotRoom[]
  isGrouped?: boolean
}

const SlotDetails = ({
  room,
  isDeleteDisabled = false,
  hasError = false,
  handleDeleteBooking,
  roomsToDelete,
  isGrouped,
}: Props) => {
  const timeSlotParts = useMemo(() => {
    return room.timeSlot.split(' - ')
  }, [room.timeSlot])

  const dateTimeString = useMemo(() => {
    return `${room.date} ${timeSlotParts[0]}`
  }, [room.date, timeSlotParts])

  const isInPast = useMemo(() => {
    if (!roomsToDelete || roomsToDelete.length === 0) {
      return false
    }

    const sortedRooms = [...roomsToDelete].sort((a, b) => {
      return a.start_time.localeCompare(b.start_time)
    })

    const firstRoom = sortedRooms[0]

    if (isGrouped) {
      const startTimeDate = `${room?.date}T${firstRoom.start_time}`
      return dayjs().isAfter(dayjs(startTimeDate))
    } else {
      const today = dayjs()
      const [bookingDate, bookingTime] = [room.date, timeSlotParts[0]]

      const bookingDateOnly = bookingDate
      const todayFormatted = today.format('YYYY-MM-DD')

      if (bookingDateOnly === todayFormatted) {
        const [hourStr, minuteStr, period] = bookingTime.match(/(\d+):(\d+)\s*(AM|PM)/i).slice(1)
        let hour = parseInt(hourStr)
        const minute = parseInt(minuteStr)

        if (period.toUpperCase() === 'PM' && hour < 12) hour += 12
        if (period.toUpperCase() === 'AM' && hour === 12) hour = 0

        const bookingDateTime = today.hour(hour).minute(minute).second(0).millisecond(0)
        return today.isAfter(bookingDateTime)
      } else {
        return dayjs().isAfter(dayjs(dateTimeString))
      }
    }
  }, [dateTimeString, isGrouped, roomsToDelete, room.date, timeSlotParts])

  return (
    <Row className='slot-container' data-error={hasError}>
      <Col xs={24}>
        <Row justify='space-between' align='middle'>
          <Col>
            <span className='room-label'>{room.name}</span>
          </Col>
          {!isInPast && (
            <Col>
              <button
                disabled={isDeleteDisabled}
                className='remove-button'
                onClick={handleDeleteBooking}>
                <RemoveIcon />
              </button>
            </Col>
          )}
        </Row>
        <Row>
          <span className='room-date'>{room.timeSlot}</span>
        </Row>
      </Col>
    </Row>
  )
}

export default SlotDetails

export const SlotDetailsList = ({
  rooms,
  isDeleteDisabled = false,
  handleDeleteBooking,
  roomsToDelete,
  isGrouped,
}: {
  rooms: RoomDetailsType[]
  isDeleteDisabled?: boolean
  handleDeleteBooking: (hasRoomError: boolean, bookingId: number) => void
  roomsToDelete: PromotionSlotRoom[]
  isGrouped?: boolean
}) => {
  return (
    <div className='slot-details-list'>
      {rooms.map((room) => {
        const hasRoomError = room?.status === 'error'
        return (
          <SlotDetails
            isDeleteDisabled={isDeleteDisabled}
            roomsToDelete={roomsToDelete}
            key={room.id}
            room={room}
            isGrouped={isGrouped}
            hasError={hasRoomError}
            handleDeleteBooking={() => handleDeleteBooking(hasRoomError, room.id)}
          />
        )
      })}
    </div>
  )
}
