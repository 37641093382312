import './scheduleCalendar.scss'
import type { FC } from 'react'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { InfoService } from 'features/Home/services/infoService'
import { DateService } from 'common/services/dateService'
import { Show } from 'common/components/Show/Show'
import {
  INFO_CONSTANTS,
  PATIENT_STATUSES,
  SMS_STATUSES,
} from 'features/Home/constants/infoConstants'
import { SMSIcon } from 'features/Home/Book/Booking/Schedule/PatientSchedule/Calendar/CalendarEvent/SMSIcon/SMSIcon'
import warningIcon from 'assets/icons/warning.svg'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { ROOM_TYPE_IDS } from 'features/Home/constants/room.constants'
import { format, parse } from 'date-fns'
import { TIME_FORMAT } from 'common/constants/timeFormat'

const { formatDisplayDate } = DateService

type CalendarEventProps = {
  event: any
}

const CalendarEvent: FC<CalendarEventProps> = ({ event }) => {
  const [previousLocation, setPreviousLocation] = useState<string | null>(null)

  const appointmentOriginalTime = useMemo(() => {
    if (event.room_type_id !== ROOM_TYPE_IDS.WAITING_ROOM) return
    const { appointment_start_time, appointment_end_time, original_room } = event
    if (!appointment_start_time || !appointment_end_time) return ''
    const startDate = parse(appointment_start_time, 'HH:mm:ss', new Date())
    const endDate = parse(appointment_end_time, 'HH:mm:ss', new Date())

    const start = format(startDate, TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS)
    const end = format(endDate, TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS)
    return `Original appointment: (${start} - ${end}, ${original_room})`
  }, [event])

  useEffect(() => {
    if (event.current_location) {
      setPreviousLocation(event.current_location)
    }
  }, [event.current_location])

  return (
    <div className='template-wrap'>
      <div className='subject'>
        <span className='truncate-text'>{event.patient.name}</span>
        <Show
          when={
            event.sms_notification !== SMS_STATUSES.UNCONFIRMED &&
            event.status.code !== PATIENT_STATUSES.CHECK_IN_REJECTED
          }>
          <div className='event-sms'>
            <SMSIcon
              status={event.sms_notification}
              color={InfoService.getScheduleColor(event.status.code).color}
            />
          </div>
        </Show>
        <Show when={event.status.code === PATIENT_STATUSES.CHECK_IN_REJECTED}>
          <div className='event-sms'>
            <img src={warningIcon} alt={ALT_CONSTANTS.STATUS_ICON} />
          </div>
        </Show>
      </div>
      <div className='time'>
        {formatDisplayDate(event.start_time, true, false, false)} -{' '}
        {formatDisplayDate(event.end_time, true, false, false)}
        <span>
          <Show when={appointmentOriginalTime}>
            <p style={{ lineHeight: '150%' }} className='rbc-event-label truncate-text'>
              {appointmentOriginalTime}
            </p>
          </Show>
        </span>
      </div>
      <Show when={event.status.code !== PATIENT_STATUSES.CHECKED_IN_ON_SITE}>
        <div className='event-description'>
          {event.status.name}
          {event.error_sms_message ? (
            <span className='error-sms-message'>({event.error_sms_message})</span>
          ) : null}
        </div>
      </Show>
      <Show when={event.status.code === PATIENT_STATUSES.CHECKED_IN_ON_SITE}>
        <p className='event-status'>
          <span>{INFO_CONSTANTS.NOW_IN} </span>
          {event.current_location
            ? event.current_location
            : previousLocation
            ? previousLocation
            : 'N/A'}
        </p>
      </Show>
      <Show
        when={
          event.status.code === PATIENT_STATUSES.CHECK_IN_REJECTED ||
          event.status.code === PATIENT_STATUSES.CHECKED_OUT
        }>
        <p className='event-status'>
          <span>{INFO_CONSTANTS.REASON}</span>
          {event.notes || 'N/A'}
          <Show when={event.temperature && event.status.code !== PATIENT_STATUSES.CHECKED_OUT}>
            <span className='event-temperature'>{` ${event.temperature}°F`}</span>
          </Show>
        </p>
      </Show>
    </div>
  )
}

export default CalendarEvent
