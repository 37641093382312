import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'

import { useAuth } from 'app/providers'
import { Button } from 'common/components/Button/Button'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
  BUTTON_SEVERITY,
} from 'common/constants/buttonConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  autoSaveSlotsAsync,
  TOGGLE_IS_CREATE_NEW_LEASE,
} from 'features/Booking/state/slices/leaseSlice'
import useUnconfirmedPopup from 'features/Home/hooks/useUnconfirmedPopup'
import { TotalAmount } from 'common/components/TotalAmount/TotalAmount'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { BOOKING_PARAMS, BOOKING_TYPE } from 'features/Booking/constants/booking.constants'
import useNavigateParams from 'common/hooks/useNavigateParams'
import { TOGGLE_IS_OPEN_CART_DETAILS, useCartManager } from 'features/Cart'
import { TOGGLE_IS_SIGN_CONTRACT } from 'features/Cart/state/slices/cartSlice'

interface IProps {
  price: number
  isOneHourSlot: boolean
}

export const LeaseSubmit: FC<IProps> = ({ price }) => {
  const [isContinueLoading, setContinueLoading] = useState(false)
  const [isCheckoutLoading, setCheckoutLoading] = useState(false)

  const { user, isUnconfirmedDoctor } = useAuth()
  const { showPopup } = useUnconfirmedPopup()
  const { updateCartContent, bookingCart } = useCartManager()
  const { data: cartData } = bookingCart ?? {}

  const dispatch = useAppDispatch()
  const navigate = useNavigateParams()

  const cantSaveLease = useMemo(() => !user || isUnconfirmedDoctor, [user, isUnconfirmedDoctor])

  const { newLeaseInfo } = useAppSelector((state) => state.leaseReducer)

  const isCheckoutButtonEnabled = useMemo(() => {
    return !!newLeaseInfo.price && !isUnconfirmedDoctor
  }, [newLeaseInfo, isUnconfirmedDoctor])

  const handleNavigateToCheckout = (): void => {
    navigate(PATH_SEGMENT.CHECKOUT, {
      [BOOKING_PARAMS.TYPE]: BOOKING_TYPE.BOOKING,
    })
  }

  const handleCloseLeaseOverlay = () => {
    dispatch(TOGGLE_IS_CREATE_NEW_LEASE())
  }

  const handleSaveSelectedTimeSlot = useCallback(async () => {
    if (cantSaveLease) {
      setCheckoutLoading(false)
      return showPopup()
    }

    const updatedLease = await dispatch(autoSaveSlotsAsync()).unwrap()
    await updateCartContent({ rooms: updatedLease })
    handleCloseLeaseOverlay()
  }, [cantSaveLease, newLeaseInfo])

  const handleCheckoutClick = useCallback(() => {
    setCheckoutLoading(true)
    handleSaveSelectedTimeSlot().then(() => {
      dispatch(TOGGLE_IS_OPEN_CART_DETAILS())

      if (!cartData?.signature) {
        dispatch(TOGGLE_IS_SIGN_CONTRACT())
        return
      }

      setCheckoutLoading(false)
      handleNavigateToCheckout()
    })
  }, [cartData])

  const handleContinueClick = useCallback(() => {
    setContinueLoading(true)
    handleSaveSelectedTimeSlot().then(() => {
      setContinueLoading(false)
      window.scrollTo({ top: 670, behavior: 'smooth' })
    })
  }, [setContinueLoading])

  const isContinueEnabled = useMemo(() => {
    // Check if there are slots in the original cart
    const hasSlotsInOriginalCart = cartData?.rooms?.some((room) =>
      room.selectedDays.some((day) => day.selectedSlots.length > 0),
    )

    // Check if there are new slots being added
    const hasNewSlots = newLeaseInfo.selectedDays?.some((day) => day.selectedSlots?.length > 0)

    // Enable the button if:
    // 1. There are slots in the original cart (allowing edits/removal)
    // 2. OR there are new slots being added
    return hasSlotsInOriginalCart || hasNewSlots
  }, [cartData, newLeaseInfo])

  return (
    <>
      <TotalAmount price={price} />

      <>
        <Button
          loading={isContinueLoading}
          onClick={handleContinueClick}
          data-cy='add-to-cart-btn'
          disabled={isCheckoutLoading || !isContinueEnabled}
          modifier={BUTTON_MODIFIER.TERTIARY80}
          proportion={BUTTON_PROPORTION.LARGE}>
          {BUTTON_CONSTANTS.CONTINUE}
        </Button>
        <Button
          loading={isCheckoutLoading}
          onClick={handleCheckoutClick}
          data-cy='checkout-btn'
          disabled={!isCheckoutButtonEnabled || isContinueLoading}
          modifier={BUTTON_MODIFIER.PRIMARY}
          severity={BUTTON_SEVERITY.SUCCESS_FILLED}
          proportion={BUTTON_PROPORTION.LARGE}>
          {BUTTON_CONSTANTS.CHECKOUT}
        </Button>
      </>
    </>
  )
}
