export enum SETTINGS_PROFILE_KEYS {
  EMAIL = 'email',
  PHONE = 'phone_number',
  OFFICE_PHONE = 'office_phone',
  SPECIALITY = 'speciality',
  COUNTRY = 'country_id',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  MEDICAL_LICENSE = 'medical_license',
  DEA_LICENSE = 'dea_license',
  STATE = 'medical_license_state_code',
  CURRENT_PASSWORD = 'current_password',
  NEW_PASSWORD = 'new_password',
  NEW_CONFIRM_PASSWORD = 'new_confirm_password',
  LEASE_NOTIFICATION = 'lease_notification',
  TICKET_NOTIFICATION = 'ticket_notification',
  MAILING_STATE = 'mailing_state_id',
  MAILING_CITY = 'mailing_city',
  MAILING_LINE1 = 'mailing_address_line_1',
  MAILING_LINE2 = 'mailing_address_line_2',
  MAILING_ZIPCODE = 'mailing_zip_code',
  PATIENT_CONFIRMATION = 'patient_confirm_notification',
  PATIENT_CANCELATION = 'patient_cancel_notification',
  PATIENT_RESCHEDULE = 'patient_reschedule_notification',
  END_OF_BOOKING = 'end_lease_notification',
  SALUTATION = 'salutation',
  QUALIFICATION = 'qualification',
  ACCEPT_EARLY_PATIENT_CHECK_IN = 'accept_early_patient_check_in',
  ACCEPT_WALK_IN_PATIENT = 'accept_walk_in_patient',
  DISPLAY_NAME = 'display_name',
}
