import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { REDUCERS } from 'common/store/reducerConstants'

import type { IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { IBookingSlice } from 'features/Booking/state/interfaces/ILeaseSlice'
import type {
  IOptionSelectFixed,
  IOptionSlot,
  IOptionSlotDay,
} from 'features/Option/interfaces/IOption'
import type { ISlotExtensionRoom } from 'features/Option/interfaces/IOptionShop'
import type {
  IOptionShopCartItem,
  IOptionShopCartOption,
  IOptionShopCartDay,
} from 'features/Option/interfaces/IOptionShopCartItem'
import { OptionService } from 'features/Option/services/optionService'
import type {
  IOptionShopItem,
  IOptionSlice,
  IOptionTypeSlot,
} from 'features/Option/state/interfaces/IOptionSlice'

const { getFilteredDaysByDate } = OptionService

const initialState: IOptionSlice = {
  optionTypeSlot: {
    price: 0,
    selectedDay: null,
    selectedDays: [],
  },
  optionShopItem: {
    quantity: 0,
    reservedSlot: null,
    selectedSlot: null,
    selectedRoom: null,
    reservedDay: null,
    selectedDay: null,
  },
  optionTypeSlots: [],
  optionTypeFixed: [],
  optionShopItems: [],
  optionExtensionRooms: [],
  optionSelectFixed: [],
  overlayOption: null,
  isOpenOptionOverlay: false,
}

export const optionSlice = createSlice({
  name: REDUCERS.OPTION,
  initialState: initialState,
  reducers: {
    SET_OVERLAY_OPTION(state, action: PayloadAction<null | ISiteRoom>) {
      state.overlayOption = action.payload
    },

    UPDATE_OPTION_SHOP_ITEM(state, action: PayloadAction<IOptionShopItem>) {
      state.optionShopItem = { ...state.optionShopItem, ...action.payload }
    },

    UPDATE_OPTION_SHOP_ITEMS(state, action: PayloadAction<IOptionShopCartItem[]>) {
      state.optionShopItems = action.payload
    },

    TOGGLE_IS_OPEN_OPTION_OVERLAY(state) {
      return { ...state, isOpenOptionOverlay: !state.isOpenOptionOverlay }
    },

    SET_OPTION_TYPE_SLOT_DAYS(state, action: PayloadAction<IOptionSlotDay[]>) {
      state.optionTypeSlot.selectedDays = action.payload
    },

    SET_OPTION_TYPE_SLOT(state, action: PayloadAction<IOptionTypeSlot>) {
      state.optionTypeSlot = action.payload
    },

    SET_OPTION_TYPE_SLOTS(state, action: PayloadAction<IOptionSlot[]>) {
      state.optionTypeSlots = action.payload
    },

    SET_OPTION_TYPE_SELECT_FIXED(state, action: PayloadAction<IOptionSelectFixed[]>) {
      state.optionSelectFixed = action.payload
    },

    RESET_OPTION_TYPE_SLOT(state) {
      state.optionTypeSlot = {
        price: 0,
        selectedDay: null,
        selectedDays: [],
      }
    },

    UPDATE_OPTION_EXTENSION_ROOMS(state, action: PayloadAction<ISlotExtensionRoom[]>) {
      state.optionExtensionRooms = action.payload
    },

    UPDATE_SELECTED_OPTION_ROOM(
      state,
      action: PayloadAction<{ bookingId: string; optionId: number }>,
    ) {
      const { optionShopItems } = state
      if (!!optionShopItems.length) {
        const reservedLease = optionShopItems.find(
          (rLease): boolean => rLease.bookingId === action.payload.bookingId,
        )
        if (reservedLease?.options) {
          const reservedRoom = reservedLease.options.find(
            (rOption): boolean => rOption.option.id === action.payload.optionId,
          )
          if (reservedRoom?.rooms?.length)
            state.optionShopItem.selectedRoom = reservedRoom.rooms.at(0)
        }
      }
    },

    UPDATE_OPTION_DAY_SLOTS(state, action: PayloadAction<IRoomTimeSlot[]>) {
      if (state.optionTypeSlot?.selectedDay) state.optionTypeSlot.selectedDay.slots = action.payload
    },

    SET_OPTION_TYPE_SELECTED_DAY(state, action: PayloadAction<IOptionSlotDay>) {
      const { selectedDays } = state.optionTypeSlot
      const reservedDay: IOptionSlotDay | undefined = selectedDays.find(
        (day: IOptionSlotDay): boolean => day.date === action.payload.date,
      )
      if (reservedDay) state.optionTypeSlot.selectedDay = { ...action.payload, ...reservedDay }
      else state.optionTypeSlot.selectedDay = action.payload
    },

    UPDATE_RESERVED_OPTION_DATA(state, action: PayloadAction<IOptionTypeSlot>) {
      state.optionTypeSlot = { ...state.optionTypeSlot, ...action.payload }
    },

    UPDATE_OPTION_TYPE_FIXED(state, action: PayloadAction<IOptionSlot[]>) {
      state.optionTypeFixed = action.payload
    },

    UPDATE_OPTION_TYPE_SELECTED_DAYS(state) {
      const { selectedDays, selectedDay } = state.optionTypeSlot
      const { selectedSlots } = selectedDay

      const isReservedDay: boolean = state.optionTypeSlot.selectedDays.some(
        (day: IOptionSlotDay): boolean => day.date === selectedDay.date,
      )

      if (isReservedDay) {
        if (!selectedSlots.length)
          state.optionTypeSlot.selectedDays = getFilteredDaysByDate<IOptionSlotDay>(
            selectedDays,
            selectedDay.date,
          )
        else
          state.optionTypeSlot.selectedDays = selectedDays.map(
            (day: IOptionSlotDay): IOptionSlotDay =>
              day.date === selectedDay.date ? selectedDay : day,
          )
      } else state.optionTypeSlot.selectedDays = [...selectedDays, selectedDay]
    },

    UPDATE_OPTION_SHOP_WITH_SINGLE_SLOT(
      state,
      action: PayloadAction<{
        optionId: number
        slot: IRoomTimeSlot
        bookingId: string
        siteId: string
      }>,
    ) {
      const { optionId, slot, bookingId, siteId } = action.payload
      const { optionShopItems, overlayOption } = state

      // Check if the option with this slot already exists for this booking ID
      const existingItem = optionShopItems.find(
        (item) =>
          item.bookingId === bookingId &&
          item.options?.some(
            (option) =>
              option.option.id === optionId && (option.slot?.id === slot.id || option.day),
          ),
      )

      if (existingItem) {
        // Remove the option if it exists with the same slot or has a day for this booking ID
        state.optionShopItems = optionShopItems
          .map((item) => {
            if (item.bookingId !== bookingId) return item
            return {
              ...item,
              options: item.options?.filter(
                (option) =>
                  !(option.option.id === optionId && (option.slot?.id === slot.id || option.day)),
              ),
            }
          })
          .filter((item) => item.options?.length > 0)
        return
      }

      // Find existing item for this booking ID
      const existingBookingItem = optionShopItems.find((item) => item.bookingId === bookingId)

      if (existingBookingItem) {
        // Add option to existing booking item
        state.optionShopItems = optionShopItems.map((item) => {
          if (item.bookingId !== bookingId) return item
          return {
            ...item,
            options: [...(item.options || []), { option: overlayOption, slot }],
          }
        })
      } else {
        // Create new booking item
        state.optionShopItems = [
          ...optionShopItems,
          {
            bookingId,
            siteId,
            options: [{ option: overlayOption, slot }],
          },
        ]
      }
    },

    UPDATE_OPTION_SHOP_WITH_SINGLE_DAY(
      state,
      action: PayloadAction<{
        optionId: number
        day: IOptionShopCartDay
        bookingId: string
        siteId: string
      }>,
    ) {
      const { optionId, day, bookingId, siteId } = action.payload
      const { optionShopItems, overlayOption } = state

      // Check if the option with this day already exists for this booking ID
      const existingItem = optionShopItems.find(
        (item) =>
          item.bookingId === bookingId &&
          item.options?.some(
            (option) =>
              option.option.id === optionId && (option.day?.date === day.date || option.slot),
          ),
      )

      if (existingItem) {
        // Remove the option if it exists with the same day or has a slot for this booking ID
        state.optionShopItems = optionShopItems
          .map((item) => {
            if (item.bookingId !== bookingId) return item
            return {
              ...item,
              options: item.options?.filter(
                (option) =>
                  !(
                    option.option.id === optionId &&
                    (option.day?.date === day.date || option.slot)
                  ),
              ),
            }
          })
          .filter((item) => item.options?.length > 0)
        return
      }

      // Find existing item for this booking ID
      const existingBookingItem = optionShopItems.find((item) => item.bookingId === bookingId)

      if (existingBookingItem) {
        // Add option to existing booking item
        state.optionShopItems = optionShopItems.map((item) => {
          if (item.bookingId !== bookingId) return item
          return {
            ...item,
            options: [...(item.options || []), { option: overlayOption, day }],
          }
        })
      } else {
        // Create new booking item
        state.optionShopItems = [
          ...optionShopItems,
          {
            bookingId,
            siteId,
            options: [{ option: overlayOption, day }],
          },
        ]
      }
    },
  },
})

export const {
  SET_OVERLAY_OPTION,
  SET_OPTION_TYPE_SLOT,
  SET_OPTION_TYPE_SLOTS,
  RESET_OPTION_TYPE_SLOT,
  UPDATE_OPTION_SHOP_ITEM,
  UPDATE_OPTION_DAY_SLOTS,
  UPDATE_OPTION_TYPE_FIXED,
  SET_OPTION_TYPE_SLOT_DAYS,
  UPDATE_RESERVED_OPTION_DATA,
  SET_OPTION_TYPE_SELECT_FIXED,
  UPDATE_SELECTED_OPTION_ROOM,
  UPDATE_OPTION_EXTENSION_ROOMS,
  TOGGLE_IS_OPEN_OPTION_OVERLAY,
  SET_OPTION_TYPE_SELECTED_DAY,
  UPDATE_OPTION_SHOP_ITEMS,
  UPDATE_OPTION_TYPE_SELECTED_DAYS,
  UPDATE_OPTION_SHOP_WITH_SINGLE_SLOT,
  UPDATE_OPTION_SHOP_WITH_SINGLE_DAY,
} = optionSlice.actions

export const updateOptionWithSingleSlot = createAsyncThunk(
  'option/updateOptionWithSingleSlot',
  async (
    {
      optionShop,
      bookingId,
      siteId,
    }: { optionShop: IOptionShopCartOption; bookingId: string; siteId: string },
    { dispatch, getState },
  ) => {
    dispatch(
      UPDATE_OPTION_SHOP_WITH_SINGLE_SLOT({
        optionId: optionShop.option.id,
        slot: optionShop.slot,
        bookingId: bookingId as string,
        siteId: siteId as string,
      }),
    )

    const updatedState = getState() as { optionReducer: IOptionSlice }
    const { optionShopItems } = updatedState.optionReducer

    return optionShopItems
  },
)

export const updateOptionWithSingleDay = createAsyncThunk(
  'option/updateOptionWithSingleDay',
  async (
    {
      optionShop,
      bookingId,
      siteId,
    }: { optionShop: IOptionShopCartOption; bookingId: string; siteId: string },
    { dispatch, getState },
  ) => {
    dispatch(
      UPDATE_OPTION_SHOP_WITH_SINGLE_DAY({
        optionId: optionShop.option.id,
        day: optionShop.day,
        bookingId: bookingId as string,
        siteId: siteId as string,
      }),
    )

    const updatedState = getState() as { optionReducer: IOptionSlice }
    const { optionShopItems } = updatedState.optionReducer

    return optionShopItems
  },
)
