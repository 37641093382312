export enum REDUCERS {
  FAQ = 'faq',
  AUTH = 'auth',
  ROOT = 'root',
  CART = 'cart',
  HOME = 'home',
  INFO = 'info',
  STAFF = 'staff',
  PATIENTS = 'patients',
  PACKAGES = 'packages',
  PROVIDER = 'provider',
  BOOKING = 'booking',
  OPTION = 'option',
  SUPPORT = 'support',
  SETTINGS = 'settings',
  ADMIN = 'admin',
}
