import type { FC } from 'react'
import { memo, useEffect, useMemo, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { Col, Row, Spin } from 'antd'
import { useFormik } from 'formik'

import { Button } from 'common/components/Button/Button'
import { Modal, ModalAction } from 'common/components/Modal'
import { Select } from 'common/components/Select/Select'
import { Show } from 'common/components/Show/Show'
import { Table } from 'common/components/Table/Table'
import { BUTTON_CONSTANTS, BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import { APP_CONSTANTS } from 'common/constants/constants'
import { MODAL_LABELS } from 'common/constants/modalConstants'
import { TABLE_ACTIONS } from 'common/constants/tableConstants'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type {
  ApiResponse,
  IRequestResponse,
  ITriggerPagRequest,
  ITriggerRequest,
  ITriggerResponse,
} from 'common/interfaces/IRequestResponse'

import { INFO_CONSTANTS } from 'features/Home/constants/infoConstants'
import { INFO_STAFF_TABLE_COLUMNS } from 'features/Home/constants/infoStaffTableColumns'
import {
  useAddLeaseStaffMutation,
  useDeleteLeaseStaffMutation,
  useFetchLeaseStatusQuery,
  useLazyFetchLeaseStaffOnAddQuery,
  useLazyFetchLeaseStaffQuery,
  useLazyResendCodeQuery,
  useLazyResetCodeQuery,
} from 'features/Home/Book/state/api/bookApi'
import type { INFO_LEASE_STATUS_TYPE } from 'features/Home/types/infoLeaseStatusType'
import type { IOptionStaff } from 'features/Option'
import { STAFF_LABELS } from 'features/Staff/constants/staffConstants'
import type { IStaffLease } from 'features/Staff/interfaces/IStaff'

import { addStaffSchema } from './addStaff.schema'
import styles from './staff.module.scss'
import { PATH_SEGMENT } from 'routes/pathSegments'
import type { StaffProps } from './staff.types'
import type { ObjectType } from 'common/interfaces/object'
import type { TableParamsType } from 'common/interfaces/ITable'
import { DEFAULT_TABLE_PARAMS } from 'common/constants/defaultTableParamsConstants'
import { TableSearchAndFilters } from 'common/components/TableSearchAndFilters/TableSearchAndFilters'

export const Staff: FC<StaffProps> = memo(() => {
  const [staffDetails, setStaffDetails] = useState<IStaffLease | null>(null)
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false)
  const [columns, setColumns] = useState<ObjectType[]>(INFO_STAFF_TABLE_COLUMNS)
  const [tableParams, setTableParams] = useState<TableParamsType>({
    ...DEFAULT_TABLE_PARAMS,
  })

  const { bookingId, siteId } = useParams()
  const { processApiResponse } = useApiResponse()

  const [resetCode, { isFetching: isResetCode }]: ITriggerRequest = useLazyResetCodeQuery()
  const [resendCode, { isFetching: isResendCode }]: ITriggerRequest = useLazyResendCodeQuery()
  const [removeStaff, { isLoading: isLoadingDeleteStaff }]: ITriggerRequest<ITriggerResponse> =
    useDeleteLeaseStaffMutation()

  const [addStaff, { isLoading: isLoadingAddStaff }]: ITriggerRequest = useAddLeaseStaffMutation()
  const { data: bookingStatus }: IRequestResponse<INFO_LEASE_STATUS_TYPE> =
    useFetchLeaseStatusQuery({ bookingId, siteId })

  const [
    fetchStaff,
    { data: staffData, isFetching: isLoadingStaff, isError, refetch: refetchStaff },
  ]: ITriggerPagRequest<{
    items: IStaffLease[]
  }> = useLazyFetchLeaseStaffQuery()

  const [
    fetchStaffOnAdd,
    { isFetching: isFetchingStaffOnAdd, data: staffCreateData },
  ]: ITriggerRequest<IOptionStaff[]> = useLazyFetchLeaseStaffOnAddQuery()

  const isBookingActive = useMemo(() => bookingStatus === 'Active', [bookingStatus])

  const initialValues = (() => {
    if (staffCreateData?.length) {
      const isSingleStaff = staffCreateData.length === 1
      const staffId = isSingleStaff ? staffCreateData[0].code : null
      const staffName = isSingleStaff ? staffCreateData[0].name : ''
      return { staffId, staffName }
    }
  })()

  const { values, errors, touched, setValues, handleSubmit, handleReset } = useFormik({
    initialValues: {
      ...initialValues,
    },
    onSubmit: handleConfirmAddStaff,
    validationSchema: addStaffSchema,
    enableReinitialize: true,
  })
  const dataSource = staffData?.items || []

  const handleResetCode = async (id: number): Promise<void> => {
    const response: ApiResponse = await resetCode({ bookingId, siteId, id })
    processApiResponse(response, {
      error: 'Reset code failed',
      success: 'Reset code success',
      successCallback: refetchStaff,
    })
  }

  const handleResendCode = async (id: number): Promise<void> => {
    const response: ApiResponse = await resendCode({ bookingId, siteId, id })
    processApiResponse(response, {
      error: 'Resend code failed',
      success: 'Resend code success',
      successCallback: refetchStaff,
    })
  }

  const handleConfirmDeleteStaff = async (): Promise<void> => {
    setStaffDetails(null)
    const response: ApiResponse = await removeStaff({ bookingId, siteId, id: staffDetails?.id })
    processApiResponse(response, {
      success: 'Staff Removed',
      error: 'Delete Staff error',
      successCallback: refetchStaff,
    })
  }

  async function handleConfirmAddStaff(): Promise<void> {
    const response: ApiResponse = await addStaff({ bookingId, siteId, id: values.staffId })

    processApiResponse(response, {
      success: 'Staff Added',
      error: 'Add Staff error',
      description: AddStaffResponseMessage,
    })
    onClosedStaffAddModal()
  }

  const onOpenStaffAddModal = async (): Promise<void> => {
    setIsAddModalVisible(true)
    fetchStaffOnAdd({ bookingId, siteId })
  }

  const onClosedStaffAddModal = () => {
    setIsAddModalVisible(false)
    handleReset(null)
  }

  useEffect(() => {
    if (Number.isInteger(staffData?.total_items))
      setTableParams({ ...tableParams, total: staffData.total_items })
  }, [staffData])

  useEffect(() => {
    if (tableParams.sorters) {
      setColumns((prev) =>
        prev.map((column) => ({ ...column, sortOrder: tableParams.sorters?.[column.key]?.order })),
      )
    }
  }, [tableParams.sorters])

  useEffect(() => {
    if (!siteId) {
      return
    }

    fetchStaff({
      siteId,
      bookingId,
      tableParams: {
        ...tableParams,
        search: tableParams.debouncedValue?.length >= 2 ? tableParams.debouncedValue : '',
      },
    })
  }, [
    tableParams.pageSize,
    tableParams.current,
    tableParams.debouncedValue,
    tableParams.sorters,
    siteId,
    bookingId,
  ])

  const AddStaffResponseMessage = (
    <p>
      <span className={styles.parentStaffAddName}>{values.staffName}</span>{' '}
      {STAFF_LABELS.GIVEN_ACCESS}
    </p>
  )

  if (isError) return <Navigate to={PATH_SEGMENT.LOGIN} />

  return (
    <div className={styles.parent}>
      <ModalAction
        isOpen={!!staffDetails}
        title={MODAL_LABELS.REMOVE_STAFF}
        confirmText={BUTTON_CONSTANTS.YES_REMOVE}
        cancelText={BUTTON_CONSTANTS.NO}
        onConfirm={handleConfirmDeleteStaff}
        onClose={() => setStaffDetails(null)}
        description={
          <>
            {STAFF_LABELS.REMOVE}
            <span className={styles.parentStaffName}>{staffDetails?.name}</span>
            {STAFF_LABELS.FROM_THIS_LEASE}
          </>
        }
      />
      <Modal
        destroyOnClose
        keyboard
        onCancel={onClosedStaffAddModal}
        open={isAddModalVisible}
        cancelButton
        title={BUTTON_CONSTANTS.ADD_STAFF}>
        <Spin spinning={isFetchingStaffOnAdd}>
          <form onSubmit={handleSubmit}>
            <div className={styles.parentAddModalContainer}>
              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <Select
                    value={values.staffId}
                    placeholder={BUTTON_CONSTANTS.SELECT}
                    listOptions={staffCreateData}
                    onChange={(staffId, { children }) =>
                      setValues({ staffId, staffName: children as string })
                    }
                  />
                  <Show when={errors.staffId && touched.staffId}>
                    <p className='errorMessage'>{errors.staffId as string}</p>
                  </Show>
                </Col>
                <Col xs={24}>
                  <Row gutter={[32, 32]} justify='end'>
                    <Col>
                      <Button
                        htmlType='button'
                        onClick={onClosedStaffAddModal}
                        modifier={BUTTON_MODIFIER.SECONDARY}>
                        {BUTTON_CONSTANTS.CANCEL}
                      </Button>
                    </Col>
                    <Col>
                      <Button htmlType='submit' loading={isLoadingAddStaff}>
                        {BUTTON_CONSTANTS.ADD}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </form>
        </Spin>
      </Modal>
      <h1 className='page-title'>{INFO_CONSTANTS.LEASE_MEDICAL_STAFF}</h1>
      <TableSearchAndFilters
        setTableParams={setTableParams}
        setColumns={setColumns}
        tableParams={tableParams}
        inputHint='Search by name, phone, email, qualification'
        {...(isBookingActive && {
          addButton: {
            label: BUTTON_CONSTANTS.ADD_STAFF,
            onClick: onOpenStaffAddModal,
          },
        })}
      />

      <Table
        tableParams={tableParams}
        setTableParams={setTableParams}
        haveActions={isBookingActive}
        itemKey='name'
        dataSource={dataSource}
        columns={columns}
        handleSendCode={(item) => {
          handleResendCode(item.id)
        }}
        handleResetCode={(item) => {
          handleResetCode(item.id)
        }}
        customLabels={{ [TABLE_ACTIONS.DELETE]: APP_CONSTANTS.REMOVE }}
        handleRemoveRow={setStaffDetails}
        actions={[TABLE_ACTIONS.DELETE, TABLE_ACTIONS.SEND_CODE, TABLE_ACTIONS.RESET_CODE]}
        isLoading={[isLoadingStaff, isLoadingDeleteStaff, isResendCode, isResetCode].includes(true)}
      />
    </div>
  )
})
