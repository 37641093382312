import type { FC } from 'react'

import { Tooltip } from 'antd'

import { useScreenWidth } from 'app/hooks/useScreenWidth'

import { ReactComponent as RemoveIcon } from 'features/Home/assets/trash.svg'

import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import { UtilService } from 'common/services/utilService'

import styles from './cartItem.module.scss'

interface IProps {
  img: string
  name: string | React.ReactNode
  price?: number
  handleEdit?: () => void
  handleDelete?: () => void
  children?: React.ReactNode
  period?: React.ReactNode
  hasEdit?: boolean
  units?: number
}

const { numberToDollar } = UtilService

export const CartItem: FC<IProps> = ({
  img,
  children,
  price,
  name,
  period,
  units,
  handleEdit = () => undefined,
  handleDelete,
  hasEdit = true,
}) => {
  const { screenWidth } = useScreenWidth()

  return (
    <div className={styles.parent}>
      <div className={styles.parentContainer}>
        <Show when={screenWidth > 580}>
          <img className={styles.parentPhoto} src={img} alt={ALT_CONSTANTS.ROOM_PHOTO} />
        </Show>
        <div className={styles.parentMoreInfo}>
          <div className={styles.parentHead}>
            <div>
              <Tooltip title={name}>
                <h4 className={styles.parentType}>{units > 1 ? `${units} x ${name}` : name}</h4>
              </Tooltip>

              <Show when={price}>
                <h4 className={styles.parentPrice}>{numberToDollar(price)}</h4>
              </Show>
              <Show when={period}>{period}</Show>
            </div>
            <Show when={hasEdit}>
              <button onClick={handleEdit} className={styles.parentEdit}>
                {BUTTON_CONSTANTS.EDIT}
              </button>
            </Show>
            <Show when={handleDelete}>
              <button onClick={handleDelete} className={styles.parentDelete}>
                <RemoveIcon width={16} height={16} />
              </button>
            </Show>
          </div>
          <Show when={screenWidth <= 580}>
            <img className={styles.parentPhoto} src={img} alt={ALT_CONSTANTS.ROOM_PHOTO} />
          </Show>
          {children}
        </div>
      </div>
    </div>
  )
}
