import { BookingUpload } from 'features/Home/Book/Booking/BookingDetails/ScreenList/BookingUpload/BookingUpload'
import styles from './media.module.scss'
import { Row, Col } from 'antd'
import { SaveButton } from 'features/Profile/components/SaveButton/SaveButton'
import { useFormik } from 'formik'
import { useNotification } from 'app/providers'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import type { IMediaFile } from 'features/Home/interfaces/IMediaFile'
import { useParams } from 'react-router-dom'
import {
  useGetMediaQuery,
  useUpdateMediaMutation,
  useRemoveMediaMutation,
  useUploadImageMutation,
} from 'features/SettingsOld/state/api/settingsApi'

const EMPTY_MEDIA_FILES: IMediaFile[] = []

export const MediaPreferences = () => {
  const { siteId } = useParams()

  const { data: media, isFetching } = useGetMediaQuery(siteId, {
    skip: !siteId,
  })

  const { setNotification } = useNotification()

  const [updateMedia] = useUpdateMediaMutation()
  const [removeMedia, { isLoading: isRemovingMedia }] = useRemoveMediaMutation()
  const [uploadImage, { isLoading: isUploading }] = useUploadImageMutation()

  const { values, dirty, isSubmitting, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      display_1: media?.display_1 ?? EMPTY_MEDIA_FILES,
      display_2: media?.display_2 ?? EMPTY_MEDIA_FILES,
    },
    enableReinitialize: true,
    onSubmit,
  })

  async function onSubmit() {
    try {
      const data = [
        ...values.display_1
          .filter((file) => !file.created_at)
          .map((file) => ({
            display_number: 1,
            name: file.name,
            path: file.url,
          })),
        ...values.display_2
          .filter((file) => !file.created_at)
          .map((file) => ({
            display_number: 2,
            name: file.name,
            path: file.url,
          })),
      ]

      await updateMedia({
        data,
        siteId,
      }).unwrap()
    } catch (error) {
      setNotification({
        type: ALERT_CONSTANTS.ERROR,
        title: 'Error',
        description: 'Failed to update media preferences',
      })
    }
  }

  const handleAddMedia = async (screen: number, file: File) => {
    try {
      const uploadResponse = await uploadImage(file).unwrap()

      const fieldName = `display_${screen}`
      setFieldValue(fieldName, [
        ...values[fieldName],
        {
          uid: Math.random().toString(),
          name: file.name,
          fileName: file.name,
          originFileObj: file,
          url: uploadResponse.file.path,
          type: file.type,
          thumbUrl: URL.createObjectURL(file),
          size: file.size,
        },
      ])
    } catch (error) {
      setNotification({
        type: ALERT_CONSTANTS.ERROR,
        title: 'Error',
        description: 'Failed to upload media',
      })
    }
  }

  const handleRemoveMedia = async (screen: number, file: IMediaFile) => {
    try {
      const hasValidUid = file?.created_at

      if (hasValidUid) {
        await removeMedia({
          mediaId: file.uid,
          siteId,
        }).unwrap()
      } else {
        const fieldName = `display_${screen}`
        setFieldValue(
          fieldName,
          values[fieldName].filter((f) => f.uid !== file.uid),
        )
      }
    } catch (error) {
      setNotification({
        type: ALERT_CONSTANTS.ERROR,
        title: 'Error',
        description: 'Failed to remove media',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <section className={styles.parent}>
        <h4 className={styles.parentTitle}>Media Preferences</h4>
        <p className={styles.parentDescription}>
          One or more rooms that you booked, give you the possibility to display custom images
          (diplomas, certificates, etc) for your patients, on dedicated displays
        </p>
        <Row
          gutter={[16, 16]}
          justify={{
            xs: 'center',
            lg: 'start',
          }}
          align='top'>
          <Col>
            <BookingUpload
              screen={1}
              isDisabled={isSubmitting || isFetching}
              isFetching={isFetching}
              uploadedFiles={values.display_1}
              handleAddMedia={(_, file) => handleAddMedia(1, file)}
              isRemovingMedia={isRemovingMedia}
              handleRemoveMedia={(_, file) => handleRemoveMedia(1, file)}
              isUploading={isUploading}
            />
          </Col>
          <Col>
            <BookingUpload
              screen={2}
              isDisabled={isSubmitting || isFetching}
              isFetching={isFetching}
              uploadedFiles={values.display_2}
              handleAddMedia={(_, file) => handleAddMedia(2, file)}
              isRemovingMedia={isRemovingMedia}
              handleRemoveMedia={(_, file) => handleRemoveMedia(2, file)}
              isUploading={isUploading}
            />
          </Col>
        </Row>
        <Row>
          <SaveButton
            htmlType='submit'
            disabled={!dirty}
            className={styles.formSubmit}
            loading={isSubmitting}
          />
        </Row>
      </section>
    </form>
  )
}
