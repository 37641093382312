import { PATH_SEGMENT } from 'routes/pathSegments'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { iconStyle } from './components/MenuWidget/MenuWidget'

const getIcon = (iconName: string) => <Icon icon={iconName} style={iconStyle} />

const showForCustomer = <T,>(isStaff: boolean, item: T): T | null => {
  return isStaff ? null : item
}

const config = {
  getMenus: (isStaff: boolean) =>
    [
      {
        key: 'account',
        label: 'My Account',
        icon: getIcon('ant-design:user-outlined'),
        children: [
          {
            key: PATH_SEGMENT.PERSONAL_PROFILE,
            label: <Link to={PATH_SEGMENT.PERSONAL_PROFILE}>Personal</Link>,
          },
          showForCustomer(isStaff, {
            key: PATH_SEGMENT.PREFERENCES,
            label: <Link to={PATH_SEGMENT.PREFERENCES}>Preferences</Link>,
          }),
          {
            key: PATH_SEGMENT.CHANGE_PASSWORD,
            label: <Link to={PATH_SEGMENT.CHANGE_PASSWORD}>Change Password</Link>,
          },
        ].filter(Boolean),
      },

      showForCustomer(isStaff, {
        key: PATH_SEGMENT.CREDIT_CARDS,
        label: <Link to={PATH_SEGMENT.CREDIT_CARDS}>Credit Cards</Link>,
        icon: getIcon('ph:credit-card-duotone'),
      }),

      showForCustomer(isStaff, {
        key: 'finance',
        label: 'Finance',
        icon: getIcon('ant-design:dollar-twotone'),
        children: [
          {
            key: PATH_SEGMENT.BILLING_HISTORY,
            label: <Link to={PATH_SEGMENT.BILLING_HISTORY}>Billing History</Link>,
          },
          {
            key: PATH_SEGMENT.PAYMENTS_HISTORY,
            label: <Link to={PATH_SEGMENT.PAYMENTS_HISTORY}>Payments History</Link>,
          },
          {
            key: PATH_SEGMENT.DEPOSIT,
            label: <Link to={PATH_SEGMENT.DEPOSIT}>Deposit</Link>,
          },
        ],
      }),
      showForCustomer(isStaff, {
        key: PATH_SEGMENT.MEDIA_PREFERENCES,
        label: <Link to={PATH_SEGMENT.MEDIA_PREFERENCES}>Media Preferences</Link>,
        icon: getIcon('solar:tv-line-duotone'),
      }),
      showForCustomer(isStaff, {
        key: PATH_SEGMENT.MAILBOX_PROFILE,
        label: <Link to={PATH_SEGMENT.MAILBOX_PROFILE}>Mailbox Profile</Link>,
        icon: getIcon('solar:mailbox-bold-duotone'),
      }),
      {
        key: PATH_SEGMENT.COMPLETED_BOOKINGS,
        label: <Link to={PATH_SEGMENT.COMPLETED_BOOKINGS}>Completed Bookings</Link>,
        icon: getIcon('ph:book-duotone'),
      },
    ].filter(Boolean),
}

export type ProfileMenuConfigType = typeof config

export const profileMenuConfig: ProfileMenuConfigType = Object.freeze(config)
