import { type FC, memo, useCallback } from 'react'

import { Skeleton, Tooltip } from 'antd'
import classNames from 'classnames'

import addIcon from 'features/SettingsOld/assets/add-icon.svg'
import cardIcon from 'features/SettingsOld/assets/card-icon.svg'

import { Button } from 'common/components/Button/Button'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_CONSTANTS, BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import { DateService } from 'common/services/dateService'
import { UtilService } from 'common/services/utilService'

import { SETTINGS_CONSTANTS } from 'features/Profile/features/Payment/constants/constants'
import { SETTINGS_CARD_CONSTANTS } from 'features/SettingsOld/constants/settingsAddCard'
import type { ISettingPaymentCard } from 'features/SettingsOld/interfaces/ISettingPaymentCard'
import { SettingsService } from 'features/SettingsOld/services/settingsService'

import styles from './PaymentCard.module.scss'

type Props = {
  isActive?: boolean
  isDisabled?: boolean
  isFetching?: boolean
  withMargin?: boolean
  isValidList?: boolean
  card?: ISettingPaymentCard
  handleAddCard?: () => void
  handleEditCard?: () => void
}

export const PaymentCard: FC<Props> = memo(
  ({
    card,
    isDisabled,
    handleAddCard,
    isFetching,
    handleEditCard,
    isValidList,
    withMargin = true,
  }) => {
    const handleCardClick = useCallback(
      (id: string) => () => {
        if (!isValidList && !id) {
          return
        }

        handleAddCard?.()
      },
      [handleAddCard, isValidList],
    )

    const handleResetClick = useCallback(
      (id: string) => (e) => {
        if (id) {
          handleEditCard?.()
          return
        }

        e?.stopPropagation()
        e?.preventDefault()

        handleAddCard?.()
      },
      [handleAddCard, handleEditCard, isValidList],
    )

    if (isFetching)
      return (
        <div
          className={classNames(styles.parentPaymentMethod, {
            [styles.parentPaymentWithMargin]: isFetching,
          })}>
          <Skeleton active paragraph={{ rows: 0 }} className={styles.parentSkeleton} />
        </div>
      )
    return (
      <div
        data-cy={`card-${card?.id}`}
        onClick={handleCardClick(card?.id)}
        className={classNames(styles.parentPaymentMethod, {
          [styles.parentPaymentDisabled]: isDisabled,
          [styles.parentPaymentWithMargin]: withMargin,
        })}>
        <div className={styles.parentPaymentInfo}>
          <Show
            when={card}
            fallback={
              <img
                src={cardIcon}
                alt={ALT_CONSTANTS.PAYMENT_TYPE_ICON}
                className={styles.parentPaymentIcon}
              />
            }>
            <Show
              fallback={card?.brand}
              when={UtilService.enumHasValue(card?.brand, SETTINGS_CARD_CONSTANTS)}>
              <img
                alt={ALT_CONSTANTS.PAYMENT_TYPE_ICON}
                className={styles.parentPaymentIcon}
                src={!card ? cardIcon : SettingsService.getPaymentCardIconSrc(card.brand)}
              />
            </Show>
          </Show>

          <span className={styles.parentPaymentType}>
            <Show
              when={card}
              fallback={
                isValidList ? SETTINGS_CONSTANTS.ADD_ANOTHER_CARD : SETTINGS_CONSTANTS.NO_CARD_ADDED
              }>
              <span className={styles.parentCurrentNumber}>**** **** **** {card?.last}</span>
            </Show>
          </span>
        </div>
        {/* Todo bug when cond fix after task */}
        <Show
          when={handleEditCard || handleAddCard}
          fallback={
            <Show when={card}>
              <span className={styles.parentDate}>
                {DateService.parseExpirationDate(card?.expMonth, card?.expYear)}
              </span>
            </Show>
          }>
          <Button
            modifier={BUTTON_MODIFIER.RESET}
            disabled={isFetching}
            onClick={handleResetClick(card?.id)}>
            <Show
              when={card}
              fallback={
                <Tooltip title={SETTINGS_CONSTANTS.ADD_A_NEW_CARD}>
                  <img src={addIcon} alt={ALT_CONSTANTS.ACTION_ICON} data-cy='add-card-btn' />
                </Tooltip>
              }>
              <span className={styles.parentEditLabel} data-cy={`card-${card?.id}-edit`}>
                {BUTTON_CONSTANTS.EDIT}
              </span>
            </Show>
          </Button>
        </Show>
      </div>
    )
  },
)
