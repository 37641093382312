import HorizontalCalendar from 'common/components/HorizontalCalendar/HorizontalCalendar'
import type { IOption } from 'common/interfaces/IOption'
import type { IRequestResponse } from 'common/interfaces/IRequestResponse'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useFetchPatientActivityDaysQuery } from 'features/Home/Book/state/api/bookApi'
import dayjs, { type Dayjs } from 'dayjs'
import useNavigateParams from 'common/hooks/useNavigateParams'
import { PathUtils } from 'routes/routes.utils'
import Show from 'common/components/Show2/Show'
import { PatientSchedule } from './PatientSchedule/PatientSchedule'
import { PatientTracker } from './PatientTracker/PatientTracker'
import { DateService } from 'common/services/dateService'
import { useAppSelector } from 'common/hooks/redux'
import styles from './schedule.module.scss'
import { PATH_SEGMENT } from 'routes/pathSegments'
import Popup from 'common/components/Popup'
import { type BookingType } from 'features/Home/interfaces/IInfo'
import { type PromotionType } from 'features/Home/Promotion/types/promotion.types'

const DATE_FORMAT = 'YYYY-MM-DD'

const Schedule = ({ booking }: { booking: BookingType | PromotionType }) => {
  const { bookingId, siteId, day } = useParams()
  const navigate = useNavigateParams()
  const location = useLocation()

  const isPromotion = useMemo(
    () => location.pathname.includes(PATH_SEGMENT.PACKAGE),
    [location.pathname],
  )

  const [defaultActiveDate, setDefaultActiveDate] = useState<string | null>(null)
  const { timeZone } = useAppSelector((state) => state.bookReducer)

  const isActiveCurrentDate = useMemo(() => {
    if (!defaultActiveDate) return false
    return DateService.getIsValidAndCurrentDay(day, timeZone)
  }, [day, timeZone, defaultActiveDate])

  const {
    data: leaseDays,
    isFetching: isFetchingLeaseDays,
    isSuccess: isSuccessFetchDays,
  }: IRequestResponse<IOption[]> = useFetchPatientActivityDaysQuery(
    {
      bookingId: bookingId,
      siteId: siteId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  const isLoading = isFetchingLeaseDays || !isSuccessFetchDays

  useEffect(() => {
    checkInitialDate()
  }, [day, leaseDays])

  function checkInitialDate() {
    if (!Array.isArray(leaseDays) || !leaseDays.length) return

    if (!day || !isValidDate(day)) {
      const today = dayjs().startOf('day')

      const nextAvailableDay = leaseDays.find((day) => {
        const leaseDayDate = dayjs(day.code, DATE_FORMAT)
        return leaseDayDate.isSame(today) || leaseDayDate.isAfter(today)
      })
      onChangeLeaseDate(String(nextAvailableDay?.code || leaseDays[0]?.code))
    } else setDefaultActiveDate(dayjs(day).format(DATE_FORMAT))
  }

  const handleNavigate = (date: string) => {
    if (isPromotion) return navigate(`${PathUtils.getPromotionSchedule(siteId, bookingId)}/${date}`)
    else navigate(`${PathUtils.getPatientSchedule(siteId, bookingId)}/${date}`)
  }

  const onChangeLeaseDate = useCallback((date: string): void => {
    setDefaultActiveDate(dayjs(date).format(DATE_FORMAT))
    handleNavigate(date)
  }, [])

  const handleBook = (date: string) => {
    if (isPromotion) return navigate(PathUtils.getPromotionDetails(siteId, bookingId))
    else navigate(PathUtils.getBook(siteId))
  }

  const handleWarningPopup = (date: string) => {
    Popup.showInfo(
      {
        title: 'No Booking Found!',
        message: "You don't have a booking for this date, would you like to book it?",
        mainButtonText: 'Book',
      },
      () => handleBook(date),
    )
  }

  function isValidDate(date: string | Dayjs) {
    return dayjs(date).isValid()
  }

  return (
    <section className={styles.container}>
      {!!defaultActiveDate && (
        <HorizontalCalendar
          isLoading={isLoading}
          defaultActiveDate={defaultActiveDate}
          availableDays={leaseDays?.map(({ code }) => String(code))}
          onDateChange={onChangeLeaseDate}
          handleClickDisabledDate={handleWarningPopup}
          minDate={dayjs()}
          maxDate={isPromotion ? dayjs(booking?.end_date) : undefined}
        />
      )}

      <Show>
        <Show.When isVisible={isActiveCurrentDate}>
          <PatientTracker />
        </Show.When>
        <Show.Else>
          <PatientSchedule />
        </Show.Else>
      </Show>
    </section>
  )
}

export default Schedule
