import type { FC } from 'react'

import { Show } from 'common/components/Show/Show'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import { UtilService } from 'common/services/utilService'

import type { IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { CartCollapse } from 'features/Cart/components/CartCollapse/CartCollapse'
import { CartItem } from 'features/Cart/components/CartItem/CartItem'
import { CART_CONSTANTS } from 'features/Cart/constants/cartConstants'
import type { IOptionShopCartOption, IOptionShopRoom } from 'features/Option'

import styles from './cartShopItem.module.scss'

interface IProps {
  shopOption: IOptionShopCartOption
  handleEdit: (option: ISiteRoom) => void
  handleRemoveSlot: (roomId: number, slotId: number, day?: string) => void
}

export const CartShopItem: FC<IProps> = ({
  handleEdit,
  handleRemoveSlot,
  shopOption: { option, rooms, amount, slot, day },
}) => {
  const price = slot?.price ?? day?.price ?? amount
  return (
    <CartItem
      price={price}
      key={option.id}
      name={option.name}
      handleEdit={() => handleEdit(option)}
      img={option.images.at(0)}>
      {slot && (
        <CartCollapse
          key={slot.id}
          title={slot.title}
          handleRemoveSlot={() => handleRemoveSlot(option.id, slot.id)}
          error={slot?.error_message}
          additionalActiveTitle={slot.title}>
          <Show when={slot?.price}>
            <div className={styles.parent}>
              {slot.title}
              <span>{UtilService.numberToDollar(slot?.price)}</span>
            </div>
          </Show>
        </CartCollapse>
      )}

      {day && (
        <CartCollapse
          title={day?.title}
          handleRemoveSlot={() => handleRemoveSlot(option.id, null, day?.date)}
          error={day?.error_message}
          additionalActiveTitle={day?.title}>
          <Show when={day?.price}>
            <div className={styles.parent}>
              {day?.title}
              <span>{UtilService.numberToDollar(day?.price)}</span>
            </div>
          </Show>
        </CartCollapse>
      )}
      {rooms?.map(({ room, selectedSlots, error_message }: IOptionShopRoom) =>
        selectedSlots.map((slot: IRoomTimeSlot) => (
          <CartCollapse
            key={slot.id}
            title={room.name}
            handleRemoveSlot={() => handleRemoveSlot(room.code as number, slot.id)}
            error={error_message}
            additionalActiveTitle={slot.title}>
            <Show when={slot?.quantity}>
              <div className={styles.parent}>
                {CART_CONSTANTS.QUANTITY} {slot.quantity} x{' '}
                {UtilService.numberToDollar(slot?.price)}
                <span>{UtilService.numberToDollar(slot.quantity * slot?.price)}</span>
              </div>
            </Show>
          </CartCollapse>
        )),
      )}
    </CartItem>
  )
}
